/**
 * This class enables all titles effects and is about
 * to be removed soon.
 * In the meanwhile in order to not break the code
 * the init method has been edited to disable all the effects
 * in titles.
 * @Deprecated
 */
export class FadeTranslate {

    constructor() {
        fama.utils.debug('Deprecated: FadeTranslate loaded')
        // this.eventListeners()
    }

    init() {

        // this line removes the opacity of all translation effect elements
        $('.js-fadeInTranslate').removeClass('opacity-0')
        $('.js-fadeIn').removeClass('opacity-0')
        $('.js-fadeUp').removeClass('opacity-0-mvl')
        $('.js-fadeMvl').removeClass('opacity-0-mvl')

        // FIX: This class is
        // this.fadeText()
        // this.fadeTextTranslate()
        // this.fadeInRight()
        // this.fadeUpYear()
        // this.fadeTextTranslateLeft()
    }

    /**
     *
     */
    eventListeners() {
        if($('.js-fadeMvl').length > 0){
            $(window).off('scroll').on('scroll', (e) => { this.checkMobileScroll() });
            $(window).trigger('scroll');
        }
    }

    /**
     *
     */
    checkMobileScroll(){
        if($('.js-fadeMvl').length > 0 ){
            var windowTop = $(document).scrollTop();

            $('.js-fadeMvl').each(function(){
                var element=$(this);
                var elementPositionTop = element.offset().top;
                var windowBottom = windowTop + window.innerHeight;

                if (elementPositionTop <= (windowBottom - 350) ) {
                    element.removeClass('opacity-0-mvl');
                }
            });
        }
    };

    /**
     *
     */
    fadeText(){
        if($('body').outerWidth() > 991){
            this.checkIntersection('.js-fadeIn', ['cssanimation', 'fadeInBottom']);
        }
    }

    /**
     * fade out image when slider image intersects viewport
     */
    fadeTextTranslate(){
        //use HOME (fama)
        if($('.isHome').length > 0 || $('body').outerWidth() > 991){
            this.checkIntersection('.js-fadeInTranslate', ['cssanimationTranslate', 'fadeInBottomTranslate']);
        }
    }

    /**
     * Fade in when intersects with viewport (Sliders)
     */

    fadeInRight(){
        //use HOME (fama)
        this.checkIntersection('.js-fadeInRight', ['js-right'], { threshold: 0.5 });


    }

    /**
     *
     */
    fadeUpYear(){
        //use HOME (fama)
        if($('body').outerWidth() > 991){
            this.checkIntersection('.js-fadeUp', ['cssanimationTranslateLeft', 'fadeInLeft']);
        }

    }

    /**
     *
     */

    fadeTextTranslateLeft(){

        //use HOME (fama)
        if( $('body').outerWidth() > 991){
            this.checkIntersection('.js-fadeInLeft', ['cssanimationTranslateLeft', 'fadeInLeft']);
        }
    }

    /**
     * Check if an element is intersecting another and add classes
     * @param {*} target
     * @param {*} classes
     * @param {*} options
     */
    checkIntersection(target, classes, options = null) {
        let callback = (entries) => {
            entries.forEach(entry => {
                if(entry.isIntersecting){
                    entry.target.classList.add(...classes);
                }
            });
        }

        let $target = $(target)
        let _options = {
            root: null,
            rootMargin: '0px',
            threshold: 1.0
        }

        _options = Object.assign({}, _options, options )

        let observer = new IntersectionObserver(callback, _options);
        if($target.length > 0){
            [...$target].forEach(item => {
                observer.observe(item);
            });
        }
    }
}
