require('../../vendor/jquery.mousewheel.min.js')
require('../../vendor/swiper.min.js')
// require('../../vendor/swiper.jquery.min.js')
require('../../vendor/fileupload/jquery.fileupload.js')
require('../../vendor/fileupload/jquery.iframe-transport.js')
require('../../vendor/fileupload/jquery.ui.widget.js')
require('../../vendor/FileUploadMan.js')
// require('../vendor/jquery-ui.min.js')
// require('../../main.legacy.js')
require('../fama.js')
