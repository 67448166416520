import { ProductDetail } from "../../classes/ProductDetail";

/**
 * Index of modules (component to show sections in products) in the detail product page.
 * '.sidebar'
 *
 * use detail product (fama/famaliving)
 */
export class ModuleIndex {
    constructor(options, app){
        console.log('ModuleIndex loaded')
        this.app = app || window.app;
        this.sidebar = options.sidebar || '.sidebar';
        this.sidebarItem = options.sidebarItem ||'.item'; // '.sidebar > .item';
        this.container = options.container || '.modules-menu';
        this.module = options.module || '.module';
        this.productDetail = new ProductDetail();
    }

    init(){
        let self = this;
        // Sidebar links
        $(".sidebar .item a").off('click').on('click', function(e){
            e.preventDefault();

            //if($(this).parent('.item').hasClass('is-active'))
                //return false;

            let href = $(this).attr('href');

            self.open(href);
        });

        $(this.sidebar + ' .prev-item').off('click').on('click', e => this.openPrev());
        $(this.sidebar + ' .next-item').off('click').on('click', e => this.openNext());

        //Button next in each module page
        $('.modules-menu .module .module-next').off('click').on('click', e => {
            e.preventDefault();
            this.openNext();
        });

        // Init sidebar
        let firstItemSidebar = $($('.sidebar').find('>.item')[0]).find('a').attr('href');
        self.open(firstItemSidebar, false);
    }

    /**
     * Only work in desktop
     */
    open(href, goToMe = true){
        if(!this.canOpen()) return false;

        let $module = $(href);
        let $nextItem = $($(`${this.sidebar} > ${this.sidebarItem} a[href="${href}"]`).closest('.item').next());
        let nextItemText = $nextItem.hasClass('item') ? $nextItem.text() : $($('.sidebar').find('>.item')[0]).text();
        if(href && $module.length){
            $(`${this.sidebar} > ${this.sidebarItem}`).removeClass('is-active');
            $(`${this.sidebar} > ${this.sidebarItem} a[href="${href}"]`).parent(this.sidebarItem).addClass('is-active');

            $module.find('.module-next a').html(nextItemText);
            let self = this;
            let showItem = () => {
                //hide all modules
                let $oldModule = $(self.container + " .module:visible:not("+ href +")");
                $oldModule.fadeOut(200, (e) => {
                    $oldModule.removeClass('isActive');
                    $module.fadeIn(() => {
                        // Initialize slider if exists or 360 iframes
                        this.productDetail.refreshModuleContent($module);
                    });
                    $module.addClass('isActive');
                });

            };

            // See if we scroll to sidebar element or not
            if (goToMe) {
                this.scrollToMe()
                .then((e) => {
                    showItem();
                });
            } else {
                showItem();
            }
        }
    }

    /**
     * Open Previous, if the current is the first, open the last module
     */
    openPrev(){
        let href = '';
        let $current = $(this.sidebar + '>' + this.sidebarItem + '.is-active');
        let $previous = $current.prev('.item');

        if($previous.length === 0){
            let $last = $(this.sidebar + '>' + this.sidebarItem).last();
            if($last.length){
                href = $last.find('a').attr('href');
            }
        }
        else{
            href = $previous.find('a').attr('href');
        }

        this.open(href);
    }

    /**
     *  Open the next module. if the current is the last, open the first module.
     */
    openNext(){
        let href = '';
        let $current = $(this.sidebar + '>' + this.sidebarItem + '.is-active');
        let $next = $current.next('.item');

        if($next.length === 0){
            let $first = $(this.sidebar + '>' + this.sidebarItem).first();
            if($first.length){
                href = $first.find('a').attr('href');
            }
        }
        else{
            href = $next.find('a').attr('href');
        }

        this.open(href);
    }

    /**
     * Only can open a modulo with the sidebar control in Desktop devices.
     * In mobile, use other system in mode accordion.
     * @returns {*}
     */
    canOpen(){
        return this.app.isDesktop();
    }

    /**
     * Scroll to top of the index of module.
     */
    scrollToMe(){
        var p = new Promise ((resolve, reject) => {
            //var top = $('.module.isActive').offset().top - $('.topbar').height(); //da error
            var top = $(window).height() - $('.topbar').height();

            $('html, body').animate({
                scrollTop: top,//$(window).height() - $('.topbar').height(),
            }, 200, 'swing', () => {
                resolve('resolved')
            });
        })
        return p;
    }
}
