export class LazyLoad {

    constructor(){
            this.images= []
    }

    init (calcRelativePosition, callback) {
        this.images = [];
        var query = this.query('img.lazy');
        $("img.lazy").data("src", "");

        for (var i = 0; i < query.length; i++) {
            this.images.push(query[i]);
        }
        ;

        this.onScroll(calcRelativePosition, callback);
    }

    query (q, res) {
        if (document.querySelectorAll) {
            res = document.querySelectorAll(q);
        } else {
            var d = document,
                a = d.styleSheets[0] || d.createStyleSheet();
            a.addRule(q, 'f:b');
            for (var l = d.all, b = 0, c = [], f = l.length; b < f; b++)
                l[b].currentStyle.f && c.push(l[b]);

            a.removeRule(0);
            res = c;
        }
        return res;
    }

    _has (obj, key) {
        return Object.prototype.hasOwnProperty.call(obj, key);
    }

    loadImage (el, fn) {
        var img = new Image(),
            src = el.getAttribute('data-src');
        //srcset = el.getAttribute('data-srcset');

        img.onload = () => {
            if (!!el.parent) {
                el.parent.replaceChild(img, el);
            }
            else {
                el.src = src;
                //el.srcset = srcset
            }

            $(el).removeClass('lazy');
            $(el).removeClass('lazy-manual');

            fn ? fn(el) : null;
        };

        img.src = src;
        //img.srcset = srcset;
    }

    elementInViewport (el, calcRelativePosition) {
        if (calcRelativePosition === true) {
            var $this = $(el);
            var $relativeParent = $this.closest('.lazy-relative-parent');
            if ($relativeParent.length > 0) {
                return ($relativeParent.position().top + $relativeParent.height() > 0 && $relativeParent.position().top < $relativeParent.closest('.lazy-relative-id').height());
            } else {
                return ($this.position().top + $this.height() > 0 && $this.position().top < $this.closest('.lazy-relative-id').height());
            }

        }

        var top = el.offsetTop;
        var left = el.offsetLeft;
        var width = el.offsetWidth;
        var height = el.offsetHeight;

        while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        return (
            top >= window.pageYOffset &&
            left >= window.pageXOffset &&
            (top + height) <= (window.pageYOffset + window.innerHeight) &&
            (left + width) <= (window.pageXOffset + window.innerWidth)
        );
    }

    onScroll (calcRelativePosition, callback) {
        for (var i = 0; i < this.images.length; i++) {
            if (this.elementInViewport(this.images[i], calcRelativePosition)) {
                this.loadImage(this.images[i], (el) => {

                    this.images.splice(i, i);
                    // Array.prototype.slice.call is not callable under our lovely IE8

                    if (typeof (callback) === "function") callback(el);

                });
            }
        }
    }
}
