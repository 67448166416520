export class ProductListManager{
    constructor(){
        this.eventListeners()
        this.init()
    }

	/**
     *
     */
    eventListeners() {
		$('.js-listGrid').on('click', (e) => {this.activeGridMode()} );
		$('.js-listList').on('click', (e) => {this.activeListMode()} );
	}

    /**
     *
     */
    init() {
        fama.utils.debug('ProductListManager loaded');
		$('.js-list').addClass('grid-mode');
	}

    /**
     *
     */
	activeGridMode() {
        this.activeMode('grid')
    }

    /**
     *
     */
    activeListMode() {
        this.activeMode('list')
    }

    activeMode(mode) {
        $('.js-listGrid').toggleClass('isActive');
        $('.js-listList').toggleClass('isActive');
        $('.js-list').removeClass('grid-mode list-mode');

        setTimeout(() => {
            $('.js-list').addClass(mode+'-mode');
        }, 200);
        //button


    }

}
