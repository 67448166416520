export class GalleryBoxFAQ {

    constructor($slider) {
        this.init($slider)
    }

    init($slider) {
        //Pasador con thumbs
        fama.utils.debug('GalleryBoxFAQ loaded');

        let $pasador = $slider.closest('.wrap-media').find('.pasadorPost.faq'),
            $pasadorPrev = $slider.closest('.wrap-media').find('.pasadorPost.faq .control-prev'),
            $pasadorNext = $slider.closest('.wrap-media').find('.pasadorPost.faq .control-next');

        let detail_new_swiper = new Swiper($pasador, {
            centeredSlides: false,

            slidesPerView: "auto",
            slideToClickedSlide: true,
            preloadImages: false,
            lazyLoadingInPrevNext: true,
            lazyLoading: true,
            prevButton: $pasadorPrev,
            nextButton: $pasadorNext,
            autoplay:false, // Rotar automáticamente 3 segundos por defecto
            loop: false, // Opción de modo de bucle

            grabCursor: true,
            shortSwipes: false,
            longSwipesRatio: 0.1,
            longSwipesMs: 100,

                breakpoints: {

                768: {
                    slidesPerView: "auto",
                    //slidesPerView: 1.2,
                    centeredSlides: true
                    },

            }
        });


        $(".pasadorPost.faq").each((i, item) => {
            let $item = $(item)
            $item.find('.swiper-slide').on('click', function () {

                var $el = $item.closest('.wrap-media').find('.swiper-id'),
                navNext = $item.closest('.wrap-media').find('.swiper-button-next'),
                navPrev = $item.closest('.wrap-media').find('.swiper-button-prev');

                //fama.utils.debug('$el: ',$el);


                var mygallerybox = new Swiper($el, {
                    initialSlide: -1,
                    centeredSlides: false,
                    slidesPerView: 1,
                    slideToClickedSlide: true,
                    preloadImages: false,
                    lazyLoading: true,
                    lazyLoadingInPrevNext: true,
                    keyboardControl: true,
                    mousewhellControl: true,
                    prevButton: navPrev,
                    nextButton: navNext,
                    spaceBetween: 0,
                    shortSwipes: false,
                    longSwipesRatio: 0.1,
                    longSwipesMs: 100,
                });

                var index = $item.index();
                //fama.utils.debug('$item: ', $item);
                mygallerybox.slideTo(0);
                var index2 = $item.closest('.wrap-media').find(".mygallerybox.faq").find('.swiper-slide-active').index();
                //fama.utils.debug('index2: 0000', index2);


                //fama.utils.debug('aqui faq1', index);

                if(index == 0 || index == null){
                    mygallerybox.setWrapperTranslate(0);
                    mygallerybox.slideTo(index,0,false);
                }else{
                    mygallerybox.slideTo(index);
                }


                $item.closest('.wrap-media').find(".mygallerybox.faq").addClass("preopen");
                $item.closest('.wrap-media').find(".mygallerybox.faq").addClass("open").removeClass("preopen");
                $("body").addClass("in-popup");

            });
        })

        $(".mygallerybox.faq .btn-close").each((i, item) =>{
            $(item).on("click",  () => {
                //fama.utils.debug('close');
                $(".mygallerybox.faq").removeClass("open");
                $("body").removeClass("in-popup");
            });
        });
    }
}
