// import { ImageManager } from "./ImageManager";

/**
 *  Splash screen handler
 *  @name SplashScreen
 *  @author LoP
 *  @refactor Ibsen
 *  @version 1.0
 */
export class SplashScreen {
    /** GalleryBox instance */
    static _instance

    /**
     * Singleton method
     * @returns instance
     */
    static getInstance() {
        if (!SplashScreen._instance) {
            SplashScreen._instance = new SplashScreen()
        }
        return SplashScreen._instance
    }

    isLoad = false
    _initialized = false;
    settings;
    /**
     * constructor
     * @param {
     *      Number maxTime(ms),
     *      String loaderClass - CSS Class of loader element
     *      Function callback
     *  } settings
     */
    constructor (settings) {
        fama.utils.debug("Splash screen loaded")
        // this.imageManager = new ImageManager()
        this.settings = {
            maxTime: 2000,
            maxTimeClicked: 5000,
            loaderClass: 'loader-intro',
            callback: function(param){fama.utils.debug("Splash callback:  " + param)}
        }
        this.settings = Object.assign({}, this.settings, settings);
        this.eventListeners()
        // this.init()
    }

    eventListeners() {

        $(() => {
            // Mask every navigation to any link with the splash screen
            // This behaviour is set after document is loaded because this component
            // is loaded usually at window.load before document is ready
            $("a").on("click", (e) => {
                var href = $(e.currentTarget).attr("href");

                if ($(e.currentTarget).attr("target") != "_blank"
                && typeof href != "undefined"
                && (href.match("^\/") || href.match(location.hostname)))
                {
                    this.showSplash(this.settings.maxTimeClicked)
                }

                //TODO: Old layouts?? can be removed?
                if (!$(e.target).is("#menu, #menu *, #menu a"))
                    $("#menu").collapse("hide");
            });
        })
    }

    /**
     *  init - Initialize the loader
     */
    init () {

        if(!this._initialized) {
            this._initialized = true
            // Wait until window is loaded
            $(document).on("load", (e) => {
                this.hideSplash('document loaded')
            });

            // If window is not loaded yet splash is removed at maxTime
            let that = this
            setTimeout( () => {
                if (!that.isLoad) {
                    that.hideSplash('timeout')
                }
            }, that.settings.maxTime);
        }

    }

    showSplash(timeOut){
        let $loader = $("#page-loader")
        $loader.fadeIn( () => {
            let $window = $(window)
            $loader.removeClass(this.settings.loaderClass);
            // var $this = $(this);
            setTimeout(() => {
                $loader.fadeOut();
            }, timeOut ?? this.settings.maxTime);
        });
    }

    hideSplash(from = ''){
        let $loader = $("#page-loader")
        let $window = $(window)
        let that = this
        if (!this.isLoad) {
            $loader.fadeOut( () => {
                $loader.removeClass(that.settings.loaderClass);
                that.isLoad = true;
                that.settings.callback(from);
            });
        }
    }
}


