import { FamaUtils } from "../../classes/FamaUtils";

export class LoginStore {

    $loginlayer = null
    utils = FamaUtils.getInstance();

    constructor() {
        fama.utils.debug("LoginStore loaded")
        this.init()
    }

    init() {
        var $loginButton = $('.js-login-shops');
        this.$loginlayer = $('#login-shops');

        $loginButton.on("click", (e) => {
            e.preventDefault();
            this.openClose();

        });


        this.$loginlayer.find(".js-login-close").on("click", (e) => {
            this.openClose();
        });

        this.$loginlayer.find(".js-login-submit").on("click", (e) => {
            e.preventDefault();
            $(e.currentTarget).closest("form").trigger('submit');
        });


        this.$loginlayer.find(".js-login-with-google-submit").on("click", (e) => {
            e.preventDefault();
            if(!window.hasGoogleToken) return false;

            var left = (screen.width/2)-(570/2);
            var top = (screen.height/2)-(570/2);

            window.open($(e.currentTarget).data('url'), '_blank', 'location=no,height=570,width=520,scrollbars=yes,status=yes, top='+top+', left='+left);
        });
    }

    submitLogin (params) {}

    openClose () {
        //fama.utils.debug('esta abierto');
        //var isIn=false;
        //estaba abierto
        //var openInit = self.$loginlayer.hasClass("in");

        var self = this;

        if(!window.hasGoogleToken) {
            var options = {
                data: {},
                onSuccess: (r) => {
                    if (r.url) {
                        self.$loginlayer.find(".js-login-with-google-submit").data('url',r.url);
                        window.hasGoogleToken = r.url;
                    }
                }
            };
            this.utils.ajaxCall('google', 'getauthurl', options);
        }

        //self.$loginlayer.attr('display', 'block');
        this.$loginlayer.toggleClass("in");

        //TODO comprobar que no afecta a famaliving
        //self.$loginlayer.toggle(".in");
    }
}
