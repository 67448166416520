/**
 *
 * @name ScrollBackgroundSphere
 * @description Handles Product page side menu (Old layout) current use in fama
 * @author - LoP
 * @refactor - Ibsen
 * @scope Fama, Famaliving
 * @version 1.0
 *
 */

export class ProductSideMenu {
    constructor() {
        fama.utils.debug('ProductSideMenu loaded')
        this.eventListeners()
        this.init()
    }

    init() {
        // Open submenu (if needed)
        this.openSubmenu();
        // Scroll to active section in menu (for mobile devices)
        this.scrollSubmenu();
    }

    eventListeners() {
        $('.sidebar-categories .arrow-dropdown').on('click',  (e) =>  { this.toggleSublist(e.currentTarget) });
	}
    scrollSubmenu() {
        let $sideBar = $($('.module-menu-sm').find('.sidebar-categories'));

        if ($sideBar.length > 0) {
            let list = $sideBar;
            let activeItem = $sideBar.find('.item.is-active');
            let activeItemPos = $(activeItem).length > 0 ? $(activeItem).offset().left : 0;// + $(activeItem).outerWidth();
            let activeHasSublist = $(activeItem).hasClass('has-sublist');

            $(list).scrollLeft(activeItemPos);

            // Scroll to active item
            if (activeHasSublist) {
                let sublist = $(activeItem).next();
                let activeSubitem = $(sublist).find('.subitem.is-active');
                let activeSubitemPos = $(activeSubitem).length > 0 ? $(activeSubitem).offset().left : 0;// + $(activeSubitem).outerWidth();

                // Scroll to active subitem
                $(sublist).scrollLeft(activeSubitemPos);
            }
        }
    }

    openSubmenu() {
        let item = $('.has-sublist.isOpen');
        let openList = $(item).next();
        let items = $(openList).find('.subitem');
        let height = 0;

        $(items).each(function (index, aItem) {
            height += $(aItem).outerHeight();
        });

        $(openList).css('height', height);
    }

    toggleSublist(button) {
        let item = $(button).closest('.item');
        let list = $(item).next();
        let height = 0;

        $(item).siblings().removeClass('is-active');

        //$(item).addClass('is-active');
        $(item).toggleClass('isOpen');

        if ($(item).hasClass('isOpen')) {
            let items = $(list).find('.subitem');

            $(items).each(function (index, aItem) {
                height += $(aItem).outerHeight();
            });
        } else {
            height = 0;
        }

        $(list).css('height', height);
    }


}
