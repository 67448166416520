import {FamaUtils} from "./FamaUtils";

export class Like {
    utils = FamaUtils.getInstance();
    _options = {
        like: true,
        unlike: true
    }

    constructor(options = {}) {
        fama.utils.debug('Like loaded')
        this._options = Object.assign({}, this._options, options)
        this.eventListeners();
    }


    eventListeners() {

        this.enableLike()
        this.enableUnlike()

        $(document).on("click", "a.share", (e) => {
            e.preventDefault();
            if ($(e.currentTarget).parent("div").find(".share-btn-container:visible").length > 0) {
                $(e.currentTarget).parent("div").find(".share-btn-container:visible").fadeOut("slow");
            } else {
                $(e.currentTarget).parent("div").find(".share-btn-container").fadeIn("slow");
            }
        });
    }

    /**
     *  Like
     */
    enableLike(){
        $(document).on('click', ".like-button", (e) => {
            e.preventDefault();

            if(this._options.like)
                this.like($(e.currentTarget))
        });
    }

    /**
     *  Unlike
     */
    enableUnlike(){
        $(document).on('click', ".liked", (e) => {
            e.preventDefault();

            if(this._options.unlike)
                this.unlike($(e.currentTarget))
        });
    }

    unlike($element) {
        let $link = $element;
        $link.toggleClass('liked like-button');
        $link.find('i').toggleClass('fa-heart fa-heart-o');

        let options = {
            data: {
                item_id: $link.data('item'),
                item_type: $link.data('type')
            },
            onSuccess: (r) => {
                if (r.status == 'ok') {
                    $link.parent().find('span.likes-count').html(parseFloat(r.count));

                    if ($(".ganadores").length) {
                        $link.closest(".premio,.finalist").find(".supertext").removeClass("supertext_liked");
                    }
                }
            }
        };
        this.utils.ajaxCall("social", "unlike", options);
    }

    /**
     *
     * @param {JQuery Selector} $element
     * @returns
     */
    like($element) {
        let $link = $element;
        if ($link.hasClass("liked"))
            return;

        $link.toggleClass('like-button liked');
        $link.find('i').toggleClass('fa-heart-o fa-heart');

        var options = {
            data: {
                item_id: $link.data('item'),
                item_type: $link.data('type')
            },
            onSuccess: (r) => {
                if (r.status == 'ok') {
                    $link.parent().find('span.likes-count').html(parseFloat(r.count));

                    if ($(".ganadores").length) {
                        $link.closest(".premio,.finalist").find(".supertext").addClass("supertext_liked");
                    }
                }
            }
        };

        this.utils.ajaxCall("social", "like", options);
    }

}
