export class TopBar {

    lastScrollTop = 0;
    prevScrollpos = window.scrollY;
    scrollTop;

    constructor () {
        fama.utils.debug('TopBar loaded')
        this.eventListeners()
        this.init()
    }

    eventListeners(){
        $(window).on('scroll', () => {
            this.handleTopBarBehaviour()
        });
    }

    init() {
        var hayActive=false;
        if($('.menu-horizontal').length > 0){
            $('.menu-horizontal').find('.item').each(function(){
                if($(this).hasClass('is-active')){
                    $(this).removeClass('no-active');
                    hayActive=true;
                }else{
                    if($(this).find('.logo').length > 0){
                        $(this).removeClass('no-active');
                    }else{
                        $(this).addClass('no-active');
                    }
                }
            })

            if(!hayActive){
                $('.menu-horizontal').find('.item').each(function(){
                    $(this).removeClass('no-active');
                })
            }

        }
    }

    handleTopBarBehaviour(){
        var inMobile = parseFloat($('body').css('width')) <= 991;
        var mob = /iphone|ipad|ipod/i.test(navigator.userAgent.toLowerCase());

        //fama.utils.debug('scroll', mob);
        var currentScrollPos = window.scrollY;
        this.scrollTop = window.scrollY || document.documentElement.scrollTop;

        // Menu Closed
        if($('#topbar').length > 0 && !$('#topbar').hasClass('is-open')){
            if (this.scrollTop > this.lastScrollTop){
                if(inMobile){
                    //fama.utils.debug('para abajo0', this.scrollTop, this.lastScrollTop );
                    $('#topbar .menu-vertical').css({height: 'calc(100vh - 3rem)'});
                }
            } else {
                if(inMobile){
                    //fama.utils.debug('para arriba0', this.scrollTop, this.lastScrollTop);
                    $('#topbar .menu-vertical').css({height: 'calc(100vh - 9rem)'});
                }
            }
            this.lastScrollTop = this.scrollTop;

            if (this.prevScrollpos >= currentScrollPos || window.pageYOffset < 102) {
                document.getElementById("topbar").style.top = "0";

            }else{
                document.getElementById("topbar").style.top = "-10.2rem";
                if(inMobile){
                    document.getElementById("topbar").style.top = "-7rem";
                }
            }
            this.prevScrollpos = currentScrollPos;


        }
        // Menu Opened
        else if ($('#topbar').hasClass('is-open')) {
            if (this.scrollTop > this.lastScrollTop){
                if(inMobile){
                    $('#topbar .menu-vertical').css({height: 'calc(100vh - 9rem)'});
                }
            } else {
                if(inMobile){
                    $('#topbar .menu-vertical').css({height: 'calc(100vh - 3rem)'});
                }

            }
            this.lastScrollTop = this.scrollTop;
        }
    }
}
