export default {
    "es":{
        closeText: 'Cerrar',
        prevText: '< Ant',
        nextText: 'Sig >',
        currentText: 'Hoy',
        monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        monthNamesShort: ['Ene','Feb','Mar','Abr', 'May','Jun','Jul','Ago','Sep', 'Oct','Nov','Dic'],
        dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        dayNamesShort: ['Dom','Lun','Mar','Mié','Juv','Vie','Sáb'],
        dayNamesMin: ['Do','Lu','Ma','Mi','Ju','Vi','Sá'],
        weekHeader: 'Sm',
        dateFormat: 'dd-mm-yy',
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: '',
        changeMonth: true,
        changeYear: true,
        yearRange: '-70:now'
    },

    'fr':{
        clearText: 'Effacer',
        clearStatus: '',
        closeText: 'Fermer',
        closeStatus: 'Fermer sans modifier',
        prevText: '<Préc',
        prevStatus: 'Voir le mois précédent',
        nextText: 'Suiv>',
        nextStatus: 'Voir le mois suivant',
        currentText: 'Courant',
        currentStatus: 'Voir le mois courant',
        monthNames: ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre'],
        monthNamesShort: ['Jan','Fév','Mar','Avr','Mai','Jun','Jul','Aoû','Sep','Oct','Nov','Déc'],
        monthStatus: 'Voir un autre mois',
        yearStatus: 'Voir un autre année',
        weekHeader: 'Sm',
        weekStatus: '',
        dayNames: ['Dimanche','Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi'],
        dayNamesShort: ['Dim','Lun','Mar','Mer','Jeu','Ven','Sam'],
        dayNamesMin: ['Di','Lu','Ma','Me','Je','Ve','Sa'],
        dayStatus: 'Utiliser DD comme premier jour de la semaine',
        dateStatus: 'Choisir le DD, MM d',
        dateFormat: 'dd-mm-yy',
        firstDay: 0,
        initStatus: 'Choisir la date',
        isRTL: false,
        changeMonth: true,
        changeYear: true,
        yearRange: '-70:now'
    },
    'de' : {
        prevText: '<Zurück',
        prevStatus: '',
        prevJumpText: '<<',
        prevJumpStatus: '',
        nextText: 'Vor>',
        nextStatus: '',
        nextJumpText: '>>',
        nextJumpStatus: '',
        currentText: 'heute',
        currentStatus: '',
        todayText: 'heute',
        todayStatus: '',
        clearText: '-',
        clearStatus: '',
        closeText: 'schließen',
        closeStatus: '',
        monthNames: ['Januar','Februar','März','April','Mai','Juni','Juli','August','September','Oktober','November','Dezember'],
        monthNamesShort: ['Jan','Feb','Mär','Apr','Mai','Jun','Jul','Aug','Sep','Okt','Nov','Dez'],
        dayNames: ['Sonntag','Montag','Dienstag','Mittwoch','Donnerstag','Freitag','Samstag'],
        dayNamesShort: ['So','Mo','Di','Mi','Do','Fr','Sa'],
        dayNamesMin: ['So','Mo','Di','Mi','Do','Fr','Sa'],
        showMonthAfterYear: false,
        showOn: 'both',
        buttonImage: 'media/img/calendar.png',
        buttonImageOnly: true,
        dateFormat:'dd-mm-yy',
        changeMonth: true,
        changeYear: true,
        yearRange: '-70:now'
    }
}
