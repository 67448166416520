export class GalleryBox {

    /** GalleryBox instance */
    static _instance

    /**
     * Singleton method
     * @returns instance
     */
    static getInstance() {
        if (!GalleryBox._instance) {
            GalleryBox._instance = new GalleryBox()
        }

        return GalleryBox._instance
    }

    isOpen = false
    $modal = null
    mygallerybox
    mygallerybox_fin

    constructor () {
        this.init();
        fama.utils.debug('GalleryBox Loaded');
    }

    /**
     *
     */
    init() {
        //Pasador con thumbs

        if($(".pasadorPost:not(.faq)").length > 0 || $(".mygallerybox:not(.faq) .swiper-id").length > 0 || $(".mygallerybox_finished .swiper-id").length > 0){

            let detail_new_swiper = new Swiper(".pasadorPost:not(.faq)", {
                centeredSlides: false,
                slidesPerView: "auto",
                slideToClickedSlide: true,
                preloadImages: false,
                lazyLoadingInPrevNext: true,
                lazyLoading: true,
                prevButton: ".control-prev",
                nextButton: ".control-next",
                autoplay:false, // Rotar automáticamente 3 segundos por defecto
                loop: false, // Opción de modo de bucle
                grabCursor: true,
                breakpoints: {

                    768: {
                        slidesPerView: "auto",
                        //slidesPerView: 1.2,
                        centeredSlides: true
                    },

                }
            });

            //Galeria en popup
            let mygallerybox = new Swiper(".mygallerybox:not(.faq) .swiper-id", {
                observer: true,
                centeredSlides: false,
                slidesPerView: 1,
                slideToClickedSlide: true,
                preloadImages: false,
                lazyLoading: true,
                lazyLoadingInPrevNext: true,
                keyboardControl: true,
                mousewheelControl: true,
                prevButton: ".swiper-button-prev:not(.fin)",
                nextButton: ".swiper-button-next:not(.fin)",
                spaceBetween: 0,
            });


            //Galeria en popup_fin
            let mygallerybox_fin = new Swiper(".mygallerybox_finished .swiper-id", {
                centeredSlides: false,
                slidesPerView: 1,
                slideToClickedSlide: true,
                preloadImages: false,
                lazyLoading: true,
                lazyLoadingInPrevNext: true,
                keyboardControl: true,
                mousewhellControl: true,
                prevButton: ".swiper-button-prev.fin",
                nextButton: ".swiper-button-next.fin",
                spaceBetween: 0,
                grabCursor: true,
            });


            this.mygallerybox = mygallerybox;
            this.mygallerybox_fin = mygallerybox_fin;
            //fama.utils.debug('this.mygallerybox', this.mygallerybox);

            this.eventListeners();
        }
    }

    /**
     *
     */
    eventListeners(){

        $(".pasadorPost:not(.fin)").find(".swiper-slide").on("click", ((e) => {
            let $element = $(e.currentTarget)
            if (this.mygallerybox.length == 1) {
                this.open($element.index());
            }
            else {
                this.open($element.index(), $element.closest(".new-blog .detail_new.pasador").data("id"));
            }

        }).bind(this));

        $(".mygallerybox_finished .btn-close").on("click", () => {
            this.close_fin();
        });

        $(".mygallerybox:not(.faq) .btn-close").on("click", () => {
            if ($(".swiper-slide-active").find("video").length > 0) {
                $(".swiper-slide-active").find("video")[0].pause();
            }
            this.close();
        });

        $("#thumb-fotos-xs").find(".swiper-slide").on("click", (e) => {
            e.preventDefault();
            this.open($(e.currentTarget).index(), "fotos-mobile");
        });

        $("#thumb-clientes-xs").find(".swiper-slide").on("click", (e) => {
            e.preventDefault();
            this.open($(e.currentTarget).index(), "clientes-mobile");
        });

        $("#thumb-videos").find(".thumb").on("click", (e) => {
            e.preventDefault();
            if ($(window).width() < 992) {
                this.open($(e.currentTarget).index(), "videos-mobile");
                // fama.utils.debug($(this).index());
            }
        });
    }

    /**
     *
     * @param {*} index
     * @param {*} id
     */
    open (index, id) {
        //fama.utils.debug('Gallerybox_2021.open');
        let mygallerybox = this.mygallerybox;
        //fama.utils.debug('index: ', index, 'id: ', id);
        index   ??= 0;

        //If id is null
        if(!id) {
            id = "";
        }

        //if id is numeric
        else if (!$.isNumeric(id) && id != "") {

            id = "#" + id;
            if (Array.isArray(mygallerybox)) {
                if (id == "#fotos-mobile") {
                    mygallerybox = mygallerybox[0];
                }
                else if (id == "#clientes-mobile") {
                    mygallerybox = mygallerybox[1];
                }
                else if ($("#clientesid-mobile").length > 0 && id == "#videos-mobile") {
                    mygallerybox = mygallerybox[2];
                }
                else if ($("#clientesid-mobile").length == 0 && id == "#videos-mobile") {
                    mygallerybox = mygallerybox[1];
                }
            }
        }

        // else
        else {

            if (Array.isArray(mygallerybox)) {
                var idx = null;
                $(".pasador-text").each( (i, elem) => {
                    if ($(elem).data("id") == id) {
                        idx = i;
                    }
                });
                $(".pasador-video").each((i, elem) => {
                    if ($(elem).data("id") == id) {
                        idx = id;
                    }
                });
                mygallerybox = mygallerybox[idx];
            }
            id = "#mygall" + id;
        }

        mygallerybox.slideTo(index, 1);
        //fama.utils.debug('aqui');

        mygallerybox.on('onTransitionEnd', () => {
            var video = $(id).find('.swiper-slide-active video');
            if (video.length && !video.attr('src')) {
                video.attr('src', video.data('src'));
            }
        });
        $(id + ".mygallerybox").addClass("preopen");
        $(id + ".mygallerybox").addClass("open").removeClass("preopen");
        $("body").addClass("in-popup");
    }

    /**
     *
     * @param {*} index
     * @param {*} id
     */
    open_fin(index, id) {
        //fama.utils.debug('Gallerybox_2021.open fin',index,id);
        var mygallerybox_fin = this.mygallerybox_fin;
        index ??= 0
        if (id == null) {
            id = "";
        }
        else {
            if (Array.isArray(mygallerybox_fin)) {
                //fama.utils.debug('open_fin2');
                var idx = null;
                $(".pasador-text").each((i, elem) => {
                    if ($(elem).data("id") == id) {
                        idx = i;
                    }
                });
                mygallerybox_fin = mygallerybox_fin[idx];
            }
            id = "#mygall" + id;
        }

        mygallerybox_fin.slideTo(index, 1);

        $(".mygallerybox_finished").addClass("preopen");
        $(".mygallerybox_finished").addClass("open").removeClass("preopen");
        $("body").addClass("in-popup");
    }

    /**
     *
     */
    close_fin() {
        $(".mygallerybox_finished").removeClass("open");
        $("body").removeClass("in-popup");
    }

    /**
     *
     * @param {*} id
     */
    close(id) {
        id ??= "";
        $(id + ".mygallerybox").removeClass("open");
        $("body").removeClass("in-popup");
    }

    /**
     *
     * @param {*} id
     */
    loadVid(id) {
        id ??= "";
        $(".video-slide").each( (i, item) => {
            if ($(item).data('id') == id) {
                var src = $(item).find('video').data('src');
                $(item).find('video').attr('src', src);
            }
        });
    }
}
