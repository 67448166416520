/**
 *
 * @name ScrollBackgroundSphere
 * @description Handles background sphere in products
 * @author - LoP
 * @refactor - Ibsen
 * @scope Fama, Famaliving
 * @version 1.0
 *
 */

import { ProductDetail } from "./ProductDetail"

export class ScrollBackgroundSphere {

    constructor() {
        fama.utils.debug('ScrollBackgroundSphere loaded')
        // this.eventListeners()
        // this.init()

        this.fixNoEffects()
    }

    init() {
        this.ballonList             = $('.ballon-list')
        this.ballon1                = $('.ballon-top')
        this.ballon2                = $('.ballon-bottom')
        this.controlGroupPrevNext   = $('.control-group-prev-next')
        this.productDetail          = new ProductDetail.getInstance();

        $(window).trigger('scroll');
    }

    /**
     * FIX: emove balloons and remove effects & events binded
     */
    fixNoEffects() {
        $('.ballon-list').remove()
        $('.ballon-top').remove()
        $('.ballon-bottom').remove()
        $('.control-group-prev-next').remove()
    }

    /**
     * Event listeners
     */
    eventListeners() {
        $(window).on('scroll', (e) => { this.manageScroll() });
    }

    /**
     * Handles scroll and fade-in with transition animatable elements when visible
     */
    manageScroll() {
        let windowHeight = window.innerHeight;
        let scrollTop = $(window).scrollTop();

        let ballonList = this.ballonList;
        let ballon1 = this.ballon1;
        let ballon2 = this.ballon2;

        // Disable hover functionality for slider in detail product if scroll > 0
        if (scrollTop > 0) {
            $(this.controlGroupPrevNext).addClass('noSpecialBehaviour');
            this.productDetail.showControls();
        } else {
            $(this.controlGroupPrevNext).removeClass('noSpecialBehaviour');
        }

        if (ballonList.length > 0) {
            let posBallonList = $(ballonList).offset().top;
            let heightBallonList = $(ballonList).height();
            let translateYBallonList;
            if ((windowHeight + scrollTop >= posBallonList) && (scrollTop <= posBallonList + heightBallonList)) {
                translateYBallonList = 300 - (posBallonList * 300 / (windowHeight + scrollTop));
            } else if (windowHeight + scrollTop <= posBallonList) {
                translateYBallonList = 0;
            } else {
                translateYBallonList = 170;
            }
            $(ballonList).css({
                'opacity': 1,
                'transform': 'translateY('+translateYBallonList+'%)'
            });
        }

        // Parallax for white ballons (in product detail)
        if (ballon1.length > 0) {
            let posBallon1 = $(ballon1).offset().top;
            let heightBallon1 = $(ballon1).height();
            let translateYBallon1;
            if ((windowHeight + scrollTop > posBallon1) && (scrollTop < posBallon1 + heightBallon1)) {
                translateYBallon1 = 120 - (posBallon1 * 120 / (windowHeight + scrollTop));
            } else if (windowHeight + scrollTop <= posBallon1) {
                translateYBallon1 = 0;
            } else {
                translateYBallon1 = 120;
            }
            $(ballon1).css('transform', 'translateY('+translateYBallon1+'%)');
        }

        if (ballon2.length > 0) {
            let posBallon2 = $(ballon2).offset().top;
            let heightBallon2 = $(ballon2).height();
            let translateYBallon2;
            if ((windowHeight + scrollTop > posBallon2) && (scrollTop < posBallon2 + heightBallon2)) {
                translateYBallon2 = 200 - (posBallon2 * 200 / (windowHeight + scrollTop));
            } else if (windowHeight + scrollTop <= posBallon2) {
                translateYBallon2 = 0;
            } else {
                translateYBallon2 = 200;
            }
            $(ballon2).css('transform', 'translateY('+translateYBallon2+'%)');
        }
    }
}
