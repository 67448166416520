import { Base64 } from "../../classes/Base64";
import { FamaUtils } from "../../classes/FamaUtils";
import configDatepicker from "../config.datepicker";

export class Resumee {

    utils = FamaUtils.getInstance();

    constructor() {
        fama.utils.debug('Resumee loaded');
        this.init()
    }

    init() {
        // Añade la selección de los tipos de carnet de conducir si está activo el checkbox.
        let $hasDrivingLicenseInput = $('.js-hasDrivingLicense');
        let $drivingLicensesBlock = $('.js-toggleDrivingLicenses');
        var lang = $('.form-work input[name="lang"]').val().trim();
        //fama.utils.debug('LANG', lang);

        if($('.js-hasDrivingLicense').is(":checked")){
           // fama.utils.debug('checked');
            $drivingLicensesBlock.show();
        }else{
            //fama.utils.debug('no checked');
            $drivingLicensesBlock.hide();
        }

        // Limita a 3 las opciones de position.
        let $positionInputs = $('.js-WorkPositionOption');
        $positionInputs.on( 'click', () => {
            let total = $('.js-WorkPositionOption:checked').length;
            if(total === 4) {
                return false;
            }
        });
        $(".curriculum_2021").each( (i, item) => {
            let $item = $(item)
            $.datepicker.regional = configDatepicker
            $.datepicker.setDefaults($.datepicker.regional[lang]);
           // fama.utils.debug('curr');
            var id = $item;
            var $errMyDataEmailPattern=id.find('form').attr('data-err-myDataEmailPattern');

            if (!this.utils.isLoad("select2", "$"))
                return;

            id.find("select[name='pais']").select2();
            id.find("select[name='carnet_conducir_tipo']").select2({ minimumResultsForSearch: -1 });

            id.find("input[name='fecha_nacimiento']").each( (i, item2) => {
                let $item2 = $(item2)
                $item2.removeAttr('id').removeClass('hasDatepicker');
                $item2.datepicker({
                    dateFormat: "dd-mm-yy",
                    changeYear: true,
                    changeMonth: true,
                    //maxDate: "-10y",
                });
            });

            $(".sends-work_2021").on('click', (e) => {
                //fama.utils.debug('enter');
                e.preventDefault();
                var form_ok = true;
                if (!$("#work_2021 .curriculum_2021 input[type='checkbox'][name='condiciones']").is(":checked")) {
                    //fama.utils.debug('form-work');
                    $("#work_2021 .curriculum_2021  input[type='checkbox'][name='condiciones']").parent().addClass('error_form');
                    form_ok = false;
                }
                else {
                    $("#work_2021 .curriculum_2021 input[type='checkbox'][name='condiciones']").parent().removeClass('error_form');
                }
                $(".curriculum_2021 input[type='text']").each( (i, item) => {
                    let $item = $(item)
                    if ($item.val() == "" &&
                        (
                            $item.attr("name") == "nombre" ||
                            $item.attr("name") == "apellidos" ||
                            $item.attr("name") == "telefono" ||
                            $item.attr("name") == "localidad" ||
                            $item.attr("name") == "codigo"

                        )
                    )

                    {
                        if ($item.parent().hasClass('textarea')) {
                            $item.parent().addClass('error_form');
                        } else {
                            $item.addClass('error_form');
                        }
                        form_ok = false;
                    }else {
                        if ($item.parent().hasClass('textarea')) {
                            $item.parent().css("border-color", "");
                            $item.addClass('error_form');
                        } else {
                            $item.css("border-color", "");
                            $item.removeClass('error_form');
                        }
                    }
                });

                $(".curriculum_2021 input[name='email']").each( (i, item) => {
                    let $item = $(item)
                    if ($item.val() == "") {
                        $item.css("border-color", "red");
                        $item.addClass('error_form');
                        form_ok = false;
                    }
                    else {
                        //fama.utils.debug('email2')
                        if(!this.utils.isEmailValid($item.val())){
                            $item.css("border-color", "red");
                            form_ok = false;
                            $item.addClass('error_form');
                            $item.siblings('.err').html($errMyDataEmailPattern);
                            //fama.utils.debug('email3')
                        }else{
                            $item.css("border-color", "");
                            $item.removeClass('error_form');
                            $item.siblings('.err').html('');
                        }
                    }
                });

                if (form_ok) {
                    var posiciones = [];
                    var intereses = [];
                    var programas = [];
                    var idiomas = [];
                    var formaciones = [];
                    var experiencias = [];
                    var otros=[];

                    $(".curriculum_2021").hide();
                    $("#page-loader").fadeIn();

                    $("input[type='checkbox'][name='posicion']:checked").each( (i, item) => {
                        posiciones.push($(item).val());
                    });

                    $("input[type='checkbox'][name='intereses']:checked").each( (i, item) => {
                        intereses.push($(item).val());
                    });
                    $(".programas").each((i, item) => {
                        let $item = $(item)
                        if (
                            $item.find('input[name="programa"]').val()
                            || $item.find('input[name="nivel"]').val()
                        ) {
                            var programa = {
                                nombre: $item.find('input[name="programa"]').val(),
                                nivel: $item.find('input[name="nivel"]').val()
                            };
                            programas.push(programa);
                        }
                    });
                    $(".idiomas").each( (i, item) => {
                        let $item = $(item)
                        if ($item.find('input[name="formacion_idioma"]').val()
                            || $item.find('input[name="formacion_nivel"]').val()
                        ) {
                            var idioma = {
                                nombre: $item.find('input[name="formacion_idioma"]').val(),
                                nivel: $item.find('input[name="formacion_nivel"]').val()
                            };
                            idiomas.push(idioma);
                        }
                    });
                    $(".formacion").each( (i, item) => {
                        let $item = $(item)
                        if (
                            $item.find('input[name="formacion_nombre"]').val() != ''
                            || $item.find('input[name="formacion_centro"]').val() != ''
                            || $item.find('input[name="formacion_desde"]').val() != ''
                            || $item.find('input[name="formacion_hasta"]').val() != ''
                            || $item.find('textarea[name="formacion_comentario"]').val() != ''
                        ) {
                            var formacion = {
                                formacion_nombre: $item.find('input[name="formacion_nombre"]').val(),
                                centro: $item.find('input[name="formacion_centro"]').val(),
                                fecha_desde: $item.find('input[name="formacion_desde"]').val(),
                                fecha_hasta: $item.find('input[name="formacion_hasta"]').val(),
                                comentario: $item.find('textarea[name="formacion_comentario"]').val()
                            };
                            formaciones.push(formacion);
                        }
                    });
                    $(".experiencia").each( (i, item) => {
                        let $item = $(item)
                        if (
                            $item.find('input[name="experiencia_empresa"]').val() != ''
                            || $item.find('input[name="experiencia_fecha_de"]').val() != ''
                            || $item.find('input[name="experiencia_fecha_a"]').val() != ''
                            || $item.find('textarea[name="experiencia_tareas"]').val() != ''
                            || $item.find('textarea[name="experiencia_comentarios"]').val() != ''
                        ) {
                            var experiencia = {
                                nombre_empresa: $item.find('input[name="experiencia_empresa"]').val(),
                                fecha_desde: $item.find('input[name="experiencia_fecha_de"]').val(),
                                fecha_hasta: $item.find('input[name="experiencia_fecha_a"]').val(),
                                tareas: $item.find('textarea[name="experiencia_tareas"]').val(),
                                comentario: $item.find('textarea[name="experiencia_comentarios"]').val()
                            };
                            experiencias.push(experiencia);
                        }
                    });

                    $(".curriculum_2021 input[name='otros_check_texto']").each((i, item) => {
                        let $item = $(item)
                        if( $item.val()!=''){
                            otros.push($item.val());
                        }
                    })

                    // // Create Base64 Object
                    // var Base64 = {
                    //     _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
                    //     encode: (e) => {
                    //         var t = "";
                    //         var n, r, i, s, o, u, a;
                    //         var f = 0;
                    //         e = Base64._utf8_encode(e);
                    //         while (f < e.length) {
                    //             n = e.charCodeAt(f++);
                    //             r = e.charCodeAt(f++);
                    //             i = e.charCodeAt(f++);
                    //             s = n >> 2;
                    //             o = (n & 3) << 4 | r >> 4;
                    //             u = (r & 15) << 2 | i >> 6;
                    //             a = i & 63;
                    //             if (isNaN(r)) {
                    //                 u = a = 64
                    //             } else if (isNaN(i)) {
                    //                 a = 64
                    //             }
                    //             t = t + this._keyStr.charAt(s) + this._keyStr.charAt(o) + this._keyStr.charAt(u) + this._keyStr.charAt(a)
                    //         }
                    //         return t
                    //     },
                    //     decode: (e) => {
                    //         var t = "";
                    //         var n, r, i;
                    //         var s, o, u, a;
                    //         var f = 0;
                    //         e = e.replace(/[^A-Za-z0-9+/=]/g, "");
                    //         while (f < e.length) {
                    //             s = this._keyStr.indexOf(e.charAt(f++));
                    //             o = this._keyStr.indexOf(e.charAt(f++));
                    //             u = this._keyStr.indexOf(e.charAt(f++));
                    //             a = this._keyStr.indexOf(e.charAt(f++));
                    //             n = s << 2 | o >> 4;
                    //             r = (o & 15) << 4 | u >> 2;
                    //             i = (u & 3) << 6 | a;
                    //             t = t + String.fromCharCode(n);
                    //             if (u != 64) {
                    //                 t = t + String.fromCharCode(r)
                    //             }
                    //             if (a != 64) {
                    //                 t = t + String.fromCharCode(i)
                    //             }
                    //         }
                    //         t = Base64._utf8_decode(t);
                    //         return t
                    //     },
                    //     _utf8_encode: function (e) {
                    //         e = e.replace(/rn/g, "n");
                    //         var t = "";
                    //         for (var n = 0; n < e.length; n++) {
                    //             var r = e.charCodeAt(n);
                    //             if (r < 128) {
                    //                 t += String.fromCharCode(r)
                    //             } else if (r > 127 && r < 2048) {
                    //                 t += String.fromCharCode(r >> 6 | 192);
                    //                 t += String.fromCharCode(r & 63 | 128)
                    //             } else {
                    //                 t += String.fromCharCode(r >> 12 | 224);
                    //                 t += String.fromCharCode(r >> 6 & 63 | 128);
                    //                 t += String.fromCharCode(r & 63 | 128)
                    //             }
                    //         }
                    //         return t
                    //     },
                    //     _utf8_decode: function (e) {
                    //         var t = "";
                    //         var n = 0;
                    //         var r = c1 = c2 = 0;
                    //         while (n < e.length) {
                    //             r = e.charCodeAt(n);
                    //             if (r < 128) {
                    //                 t += String.fromCharCode(r);
                    //                 n++
                    //             } else if (r > 191 && r < 224) {
                    //                 c2 = e.charCodeAt(n + 1);
                    //                 t += String.fromCharCode((r & 31) << 6 | c2 & 63);
                    //                 n += 2
                    //             } else {
                    //                 c2 = e.charCodeAt(n + 1);
                    //                 c3 = e.charCodeAt(n + 2);
                    //                 t += String.fromCharCode((r & 15) << 12 | (c2 & 63) << 6 | c3 & 63);
                    //                 n += 3
                    //             }
                    //         }
                    //         return t
                    //     }
                    // }

                    var uploaded_image = '';
                    var uploaded_cv = '';
                    var inputFoto = $("input[type='file'][name='foto']")[0];
                    var inputCV = $("input[type='file'][name='cv']")[0];
                    var foto = inputFoto.files[0];
                    var cv = inputCV.files[0];
                    var fd = new FormData();
                    fd.append("afoto", foto);
                    fd.append("acv", cv);

                    //TODO: use axios??
                    var xhr = new XMLHttpRequest();
                    xhr.open('POST', "/ajax.php?module=curriculums&action=uploadcontent", true);

                    xhr.onload = (r) => {
                        if (r.status == 200) {
                            uploaded_image = JSON.parse(r.response).imgUp;
                            uploaded_cv = JSON.parse(r.response).cv;
                        };

                        var options = {
                            nombre: $(".curriculum_2021 input[name='nombre']").val(),
                            apellidos: $(".curriculum_2021 input[name='apellidos']").val(),
                            email: $(".curriculum_2021 input[name='email']").val(),
                            fecha_nacimiento: $(".curriculum_2021 input[name='fecha_nacimiento']").val(),
                            telefono: $(".curriculum_2021 input[name='telefono']").val(),
                            direccion: $(".curriculum_2021 input[name='direccion']").val(),
                            localidad: $(".curriculum_2021 input[name='localidad']").val(),
                            provincia: '',
                            codigo: $(".curriculum_2021 input[name='codigo']").val(),
                            pais: $(".curriculum_2021 select[name='pais']").val(),
                            comentario: $(".curriculum_2021 textarea[name='comentario']").val(),
                            actividadesintereses_comentario: $(".curriculum_2021 textarea[name='actividadesintereses_comentario']").val(),
                            residencia_extranjero: $(".curriculum_2021 input[name='residencia_extranjero']").val(),
                            residencia_extranjero_check: $(".curriculum_2021 input[type='radio'][name='extranjero']:checked").val(),
                            disponible_viajar: $(".curriculum_2021 input[type='checkbox'][name='disponible_viajar']:checked").val(),
                            carnet_conducir: $(".curriculum_2021 input[type='checkbox'][name='carnet_conducir']:checked").val(),
                            carnet_conducir_tipo: $(".curriculum_2021 select[name='carnet_conducir_tipo']").val(),
                            experiencia_voluntario: $(".curriculum_2021 input[type='checkbox'][name='experiencia_voluntario']:checked").val(),
                            experiencia_voluntario_texto: $(".curriculum_2021 input[name='experiencia_voluntario_texto']").val(),
                            premios_obtenidos: $(".curriculum_2021 input[type='checkbox'][name='premios_obtenidos']:checked").val(),
                            premios_obtenidos_texto: $(".curriculum_2021 input[name='premios_obtenidos_texto']").val(),
                            otros_check: $(".curriculum_2021 input[type='checkbox'][name='otros_check']:checked").val(),
                            otros_comentario: $(".curriculum_2021 textarea[name='otros_comentario']").val(),
                            conoce_en_fama: $(".curriculum_2021 input[type='radio'][name='conoce_en_fama']:checked").val(),
                            conoce_nombre: $(".curriculum_2021 input[name='conoce_nombre']").val(),
                            conocimiento_empresa: $(".curriculum_2021 textarea[name='conocimiento_empresa']").val(),
                            lang: $(".curriculum_2021 input[name='lang']").val()
                        }

                        if (otros.length > 0) {
                            options.otros = otros;
                        }

                        if (uploaded_image) {
                            options.imagen = Base64.encode(uploaded_image);
                        }
                        if (uploaded_cv) {
                            options.curriculum_file = Base64.encode(uploaded_cv);
                        }
                        if (posiciones.length > 0) {
                            options.posiciones = posiciones;
                        }
                        if (intereses.length > 0) {
                            options.intereses = intereses;
                        }
                        if (programas.length > 0) {
                            options.programas = programas;
                        }
                        if (idiomas.length > 0) {
                            options.idiomas = idiomas;
                        }
                        if (formaciones.length > 0) {
                            options.formaciones = formaciones;
                        }
                        if (experiencias.length > 0) {
                            options.experiencias = experiencias;
                        }

                        //fama.utils.debug('options', options);
                        $.ajax({
                            url: "/ajax.php?module=curriculums&action=contact&language=" + options.lang,
                            data: options,
                            type: "post",
                            async: true,
                            dataType: "json",
                            beforeSend:  ()  => {},
                            success: (r1) => {
                                //fama.utils.debug('sucess');
                                alert(r1.message);
                                $(".curriculum_2021 input[type='text']").val('');

                                $(".curriculum_2021 textarea").val('');

                                $(".curriculum_2021 input[type='checkbox']").prop("checked", false);
                                $(".curriculum_2021 input[type='radio']").prop("checked", false);

                                $(".curriculum_2021 .preview_img").attr('src', '/images/avatar_workWithUs.svg');

                                $(".cv_file").html('');
                                $('.cv_file').removeClass('isSee');

                                $('.curriculum_2021 .js-toggleDrivingLicenses').hide();

                                $('.curriculum_2021 select[name="pais"]').find('option[value="0"]').attr('selected','selected');
                                $('.curriculum_2021 select[name="pais"]').prop('selectedIndex',0);

                                $('.curriculum_2021 select[name="pais"]').select2('destroy');
                                $('.curriculum_2021 select[name="pais"]').find("select option").eq(0).html();
                                $('.curriculum_2021 select[name="pais"]').select2();

                                $('.curriculum_2021 select[name="carnet_conducir_tipo"]').find('option[value="0"]').attr('selected','selected');
                                $('.curriculum_2021 select[name="carnet_conducir_tipo"]').prop('selectedIndex',0);

                                $('.curriculum_2021 select[name="carnet_conducir_tipo"]').select2('destroy');
                                $('.curriculum_2021 select[name="carnet_conducir_tipo"]').find("select option").eq(0).html();
                                $('.curriculum_2021 select[name="carnet_conducir_tipo"]').select2();

                                $(".curriculum_2021").show();
                                $("#page-loader").fadeOut();
                            }
                        });
                    };

                    xhr.send(fd);

                } else {
                    //fama.utils.debug('hay errores');
                    //fama.utils.debug($("#work_2021 .error_form")[0]);
                    $('html, body').animate({
                        scrollTop: $($("#work_2021 .error_form")[0]).offset().top - $(".mytopbar").height()
                    }, 2000);
                    $($("#work_2021 .error_form")[0]).trigger('focus');
                }
            });

            $(".curriculum_2021 .triggerAdd").off().on('click' , (e) => {
                e.preventDefault();
                $(".curriculum_2021 input[type='file'][name='foto']").trigger('click');
            });

            $(".curriculum_2021 .uploadCv").off().on('click' , (e) => {
                e.preventDefault();
                $(".curriculum_2021 input[type='file'][name='cv']").trigger('click');
            });
            $(".curriculum_2021 .addFormacion").off().on('click' , (e) => {
                e.preventDefault();
                $($(".curriculum_2021 .formacion")[0]).clone().appendTo($($(".formacion")[0]).parent());
                $(".curriculum_2021 .formacion:last input, .formacion:last textarea").val('');

                $(".curriculum_2021 .formacion:last").find("input[name='formacion_hasta']").each((i, item) => {
                    let $item = $(item)
                    $item.removeAttr('id').removeClass('hasDatepicker');
                    $item.datepicker({dateFormat: 'dd-mm-yy', changeYear: true, maxDate: new Date(), changeMonth: true,});
                });
                $(".curriculum_2021 .formacion:last").find("input[name='formacion_desde']").each((i, item) => {
                    let $item = $(item)
                    $item.removeAttr('id').removeClass('hasDatepicker');
                    $item.datepicker({dateFormat: 'dd-mm-yy', changeYear: true, changeMonth: true, maxDate: new Date(),});
                });
            });
            $(".curriculum_2021 .addExperiencia").off().on('click', (e) => {
                e.preventDefault();
                $($(".experiencia")[0]).clone().appendTo($($(".experiencia")[0]).parent());
                $(".experiencia:last input, .experiencia:last textarea").val('');

                $(".experiencia:last").find("input[name='experiencia_fecha_de']").each((i, item) => {
                    let $item = $(item)
                    $item.removeAttr('id').removeClass('hasDatepicker');
                    $item.datepicker({dateFormat: 'dd-mm-yy', changeYear: true, maxDate: new Date(), changeMonth: true,});
                });
                $(".experiencia:last").find("input[name='experiencia_fecha_a']").each((i, item) => {
                    let $item = $(item)
                    $item.removeAttr('id').removeClass('hasDatepicker');
                    $item.datepicker({dateFormat: 'dd-mm-yy', changeYear: true, changeMonth: true, maxDate: new Date(),});
                });
            });
            $(".curriculum_2021 .addIdioma").off().on('click', (e) => {
                e.preventDefault();
                $($(".idiomas")[0]).clone().appendTo($($(".idiomas")[0]).parent());
                $(".idiomas:last input").val('');
            });
            $(".curriculum_2021 .addPrograma").off().on('click', (e) => {
                e.preventDefault();
                $($(".programas")[0]).clone().appendTo($($(".programas")[0]).parent());
                $(".programas:last input").val('');
            });
            $(".curriculum_2021 input[type='file'][name='foto']").on('change',  (e) => {
                e.preventDefault();
                if ($(e.currentTarget)[0].files && $(e.currentTarget)[0].files[0]) {
                    var reader = new FileReader();

                    reader.onload = (e) => {
                        $('img.preview_img').attr('src', e.target.result);
                    }

                    reader.readAsDataURL($(e.currentTarget)[0].files[0]);
                }
            });

            $(".curriculum_2021 input[type='file'][name='cv']").on('change', (e) => {
                e.preventDefault();
                let $target = $(e.currentTarget)
                if ($target[0].files && $target[0].files[0]) {
                    var type = $target[0].files[0].type;
                    var type_reg = /^application\/(msword|pdf)$/;
                    if (type_reg.test(type)) {
                        $('.cv_file').html($target[0].files[0].name);
                        $('.cv_file').addClass('isSee');
                    } else {
                        alert($("#formato_no_valido").val());
                        $('.cv_file').removeClass('isSee');
                    }
                }
            });

            $("body").on('keyup', ".error_form", (e) => {
                let $target = $(e.currentTarget)
                if ($target.val() != '') {
                    $target.removeClass('error_form');
                    $target.css("border-color", "");
                }
            });
            $("body").on('keyup', ".error_form textarea", (e) => {
                let $target = $(e.currentTarget)
                if ($target.val() != '') {
                    $target.parent().removeClass('error_form');
                    $target.css("border-color", "");
                }
            });

            id.find("input[name='formacion_desde']").datepicker({
                dateFormat: "dd-mm-yy",
                changeYear: true,
                changeMonth: true,
                maxDate: new Date(),
            });
            id.find("input[name='formacion_hasta']").datepicker({
                dateFormat: "dd-mm-yy",
                changeYear: true,
                changeMonth: true,
                maxDate: new Date(),
            });
            id.find("input[name='experiencia_fecha_de']").datepicker({
                dateFormat: "dd-mm-yy",
                changeYear: true,
                changeMonth: true,
                maxDate: new Date(),
            });
            id.find("input[name='experiencia_fecha_a']").datepicker({
                dateFormat: "dd-mm-yy",
                changeYear: true,
                changeMonth: true,
                maxDate: new Date(),
            });
        });
    }
}
