import { FamaUtils } from "../../classes/FamaUtils";
import { LazyLoad } from "../../classes/LazyLoad";
import { GalleryBox } from "./GalleryBox";

/**
 * Contest form handler
 */
export class Contest {

    utils = FamaUtils.getInstance()
    gallery
    lazyLoad = new LazyLoad()

    constructor() {
        this.gallery = GalleryBox.getInstance()
        this.init()
        fama.utils.debug('Contest Loaded');

    }

    init(){

        $('.uploadErr').html('');
        $('.uploadErr').removeClass('err');

        $(document).find(".competitionDetail_2021").each((i,item) => {
            let $item = $(item)
            if (!this.utils.isLoad("select2", "$"))
            return;

            if($("select[name='pais']").length > 0){
                $item.find("select[name='pais']").select2();
            }
            if($("select[name='blanket']").length > 0){
                $item.find("select[name='blanket']").select2();
            }

            $(".js-ampliarFin_2021").on("click", (e) => {
                e.preventDefault();
                //fama.utils.debug('$item.data("pos")', $item.attr("data-pos"));
                this.gallery.open_fin($item.data("pos"));
            });

            var items = $item.find(".block-photo");

            items.find(".js-votar").on("click", (e) => {
                e.preventDefault();
                $element = $(e.currentTarget)
                var $id = $element.closest(".block-photo");
                var $box = $id.find(".votar-box");
                var $background = $id.find(".wrap-background");

                $(".wrap-background").removeClass("in");
                $(".block-photo").removeClass("in");
                $(".votar-box").removeClass("in");

                if ($box.is(".in")) {
                    //$box.slideUp();
                    $background.removeClass("in");
                    $box.removeClass("in");
                    $id.removeClass("in");
                }
                else {
                    //$box.slideDown();
                    $background.addClass("in");
                    $box.addClass("in");
                    $id.addClass("in");
                }
            });

            items.find(".js-votarClose").on("click", (e) => {
                e.preventDefault();
                $element = $(e.currentTarget)
                var $id = $element.closest(".block-photo");
                var $box = $id.find(".votar-box");
                var $background = $id.find(".wrap-background");

                //TODO: Remove all at once
                $box.find($('input[type="text"]')).each((i, item) => {
                    $(item).removeClass('error');
                });

                $(".wrap-background").removeClass("in");
                $(".block-photo").removeClass("in");
                $(".votar-box").removeClass("in");

                if ($box.is(".in")) {
                    $background.removeClass("in");
                    $box.removeClass("in");
                    $id.removeClass("in");
                }
            });


            items.find(".js-ampliar_2021").on("click", (e) => {
                e.preventDefault();
                let $element = $(e.currentTarget)
                fama.utils.debug($element.data("pos"), $element.data("id"));
                this.gallery.open($element.data("pos"), $element.data("id"));
            });

            items.find(".js-videos-ampliar").on("click", (e) => {
                e.preventDefault();
                let $element = $(e.currentTarget)
                this.gallery.open($element.data("pos"), $element.data('id'));
                this.gallery.loadVid($element.data("item"));
            });

            items.find(".close-modal").on("click", (e) => {
                e.preventDefault();
                let $element = $(e.currentTarget)
                var $id = $element.closest(".block-photo");
                var $photo = $id.find(".photo");
                if ($photo.is(".photo-modal")) {
                    $photo.removeClass("photo-modal");
                }
                else {
                    $photo.addClass("photo-modal");
                }
            });
        });

        $("#contest.competitionDetail_2021").each((i, item) => {
            this.lazyLoad.init($(item));
            $(document).on('scroll', () => {
                this.lazyLoad.onScroll($(item))
                // LazyLoad.init(false);
            }).trigger('scroll');

            // PARTICIPAR
            $("#send-concurso").on("click", (e) => {
                //fama.utils.debug('#send-concurso');
                e.preventDefault(e);
                let envia=true;
                let form=$('.form-paticipate');
                let $selectFile=form.attr('data-selectFile');

                // START validation front
                form.find($('input[type="text"]')).each( (i, item) => {
                    let $item = $(item)
                    if($item.attr('name') != 'rate_product'){
                        //fama.utils.debug('aqui');
                        if ($.trim($item.val()) == "") {
                            $item.addClass('error');
                            //$item.css("border-color", "red");
                            envia = false;
                        }
                        else {
                            $item.removeClass('error');
                            //$item.css("border-color", "");
                        }
                    }

                });


                if ($('.form-paticipate input[id="fileupload_2021"]').val() == "") {
                    envia = false;
                    $('.form-paticipate .upload-progress-bar_2021').addClass('error');
                    $('.form-paticipate .uploadErr').html($selectFile);
                }else{
                    $('.form-paticipate .upload-progress-bar_2021').removeClass('error');
                    $('.form-paticipate .uploadErr').html('');
                }


                $(".form-paticipate input[type='email']").each((i, item) => {
                    let $item = $(item)
                    if (($item.val()).trim() == "") {
                        $item.addClass('error');
                        //$item.css("border-color", "red");
                        envia = false;
                    }
                    else {
                        if(!this.utils.isEmailValid($item.val())){
                            $item.addClass('error');
                            //$item.css("border-color", "red");
                            envia = false;
                        }else{
                            $item.removeClass('error');
                            //$item.css("border-color", "");
                        }
                    }
                });

                if ($.isNumeric($(".form-paticipate select[name='pais']").val() == "") || $(".form-paticipate select[name='pais']").val() <= 0) {
                    $('.form-paticipate select[name="pais"]').closest('.form-group').addClass("error");
                    envia = false;
                }
                else {
                    $('.form-paticipate select[name="pais"]').closest('.form-group').removeClass("error");
                }

                if ($.isNumeric($(".form-paticipate select[name='blanket']").val() == "") || $(".form-paticipate select[name='blanket']").val() <= 0) {
                    //fama.utils.debug('select2-blanket1', $(".form-paticipate select[name='blanket']").val());
                    $('.form-paticipate select[name="blanket"]').closest('.form-group').addClass("error");
                    envia = false;
                }
                else {
                    //fama.utils.debug('select2-blanket2');
                    $('.form-paticipate select[name="blanket"]').closest('.form-group').removeClass("error");
                }

                if (!$(".form-paticipate input[type='checkbox']").is(":checked")) {
                    $(".form-paticipate input[type='checkbox']").parent().addClass('error');
                    envia = false;
                }
                else {
                    $(".form-paticipate input[type='checkbox']").parent().removeClass('error');
                }

                if( $('.form-paticipate .imgUpLoad').attr('src')===""){
                    envia = false;
                    $('.upload-progress-bar_2021').addClass('error');
                }
                else {
                    $('.upload-progress-bar_2021').removeClass('error');
                }

                // END validation front

                if (envia) {
                    var lang = $("#contest.competitionDetail_2021").attr("data-lang");
                    $('.loader.loader-intro').fadeIn();

                    form.trigger('submit');

                    this.clearForm()

                } else {
                    $('html, body').animate({
                        scrollTop: $($(".form-paticipate .error")[0]).offset().top - $(".topbar").height() - 50
                    }, 800);
                    $($(".form-paticipate .error")[0]).trigger('focus');
                }

            });


            // VOTAR_2021
            $(".competitionDetail_2021 .send_votar").on("click", (e) => {
                //fama.utils.debug('.send_votar');
                e.preventDefault();
                var $box = $(e.currentTarget).closest(".votar-box");
                var $boxBackground = $box.closest(".wrap-background");

                var iw4id = $box.attr("data-iw4id"),
                    name = $box.find('input[name="nombre"]'),
                    email = $box.find('input[name="email"]'),
                    competitiontype = $box.attr("data-competitiontype");

                var $errMyDataEmailPattern=$box.find('.form-votar').attr('data-err-myDataEmailPattern');
                var envia = true;

                if ((email.val()).trim() == "") {
                    email.addClass('error');
                    envia = false;
                }
                else {
                    if(!this.utils.isEmailValid(email.val())){
                        email.addClass('error');
                        email.closest('.input-box').find('.err').html($errMyDataEmailPattern);
                        envia = false;
                    }else{
                        email.removeClass('error');
                        email.closest('.input-box').find('.err').html('');
                    }
                }


                if ((name.val()).trim() == "") {
                    name.addClass('error');
                    envia = false;
                } else {
                    name.removeClass('error');
                }


                if (envia) {
                    fama.utils.debug('ok');
                    var lang = $("#contest.competitionDetail_2021").attr("data-lang");
                    var options = {}
                    $.ajax({
                        url: "/ajax.php?module=concursos&action=vote&iw4id=" + iw4id + "&language=" + lang + "&name=" + name.val() + "&email=" + email.val() + "&competitiontype=" + competitiontype,
                        data: options,
                        type: "get",
                        async: true,
                        dataType: "json",
                        beforeSend: () => {
                        },
                        success: (r) => {
                            if (r.status == "ok") {
                                alert($("#i18n_concurso_gracapp.Contest_2021.init();ias_por_votar").html());
                                $(".competitionDetail_2021 .js-votar").remove();
                            }
                            else {
                                alert($("#i18n_concurso_no_mas_votos").html());
                            }
                        }
                    });
                    $box.find("input").val("");
                    $box.toggleClass("in");
                    $boxBackground.toggleClass("in");
                }
            });

        });

        $('.upload-progress-bar_2021').off('click').on('click',
            (e) => {
                //fama.utils.debug('bar click');
                e.preventDefault();
                e.stopImmediatePropagation()
                $('#fileupload_2021').trigger('click');
            }
        );

        $('#fileupload_2021').on('change' , (e) => {
            $('.uploadErr').html('');
            $('.uploadErr').removeClass('err');
            //fama.utils.debug('previewFile');
            var preview = document.querySelector('img[class="imgUpLoad"]');
            var file    = document.querySelector('input[id="fileupload_2021"]').files[0];
            let fileSizeBytes=file.size;
            let fileSizeKiloBytes=parseInt(fileSizeBytes / 1024);
            let maxSize=4000;

            var reader  = new FileReader();

            let invalidSizeExtension=$('#i18n-up_text_invalid_file_client').text();
            let invalidSize=$('#i18n-up_text_invalid_file_size').text();
            let selectImg=$('#i18n-up_text_selectImg').text();
            let uploading= $('#i18n-up_text_uploading').text();
            let uploadingWait= $('#i18n-up_text_uploading_wait').text();
            let uploadedOK= $('#i18n-up_text_uploadok').text();
            let uploadedKO= $('#i18n-up_text_uploadko').text();
            let uploadAtLeast= $('#i18n-up_text_first_select_a_file').text();
            let uploadLimitReached= $('#up_text_limit_reached').text();

            if (!(/\.(jpg|png|gif|jpeg)$/i).test(file.name)) {
                //alert('El archivo a adjuntar no es una imagen');
                $('.uploadErr').addClass('err');
                $('.uploadErr').html(invalidSizeExtension);
                $('.upload-progress-bar_2021').addClass('error');
                preview.src="";
            } else {
                //fama.utils.debug('comprobar tamaño', fileSizeKiloBytes);
                if(fileSizeKiloBytes>maxSize){
                    $('.uploadErr').addClass('err');
                    $('.uploadErr').html(invalidSize);
                    $('.upload-progress-bar_2021').addClass('error');
                    preview.src="";
                    document.querySelector('img[class="imgUpLoad"]').style.visibility = "hidden";
                    document.querySelector('img[class="imgUpLoad"]').style.height = "0";
                    document.querySelector('img[class="imgUpLoad"]').style.padding = "0 0 0 0";
                    $('.form-paticipate .add-file').html(selectImg);

                    //fama.utils.debug('file', file);
                    //fama.utils.debug('file val', file.val());
                }else{
                    setTimeout(() => {
                        reader.addEventListener("load", function () {
                            // convierte la imagen a una cadena en base64
                            preview.src = reader.result;

                        }, false);

                        if (file) {
                            reader.readAsDataURL(file);
                            document.querySelector('img[class="imgUpLoad"]').style.height = "auto";
                            document.querySelector('img[class="imgUpLoad"]').style.padding = "0 0 25px 0";
                            document.querySelector('img[class="imgUpLoad"]').style.visibility = "visible";
                            $('.form-paticipate .add-file').html(file.name);
                            $('.upload-progress-bar_2021').removeClass('error');
                            //$('.uploadErr').removeClass('err');
                            //$('.uploadErr').html('');

                        } else {
                            preview.src = "";
                            $('.form-paticipate .add-file').html(selectImg);
                        }
                    },200)
                }

            }
        });

    }

    clearForm() {
        $('.form-paticipate .imgUpLoad').attr('src', '');
        $('.form-paticipate input[name="concurso-nombre"]').val(''),
        $('.form-paticipate input[name="concurso-apellidos"]').val(''),

        $('.form-paticipate input[name="concurso-email"]').val(''),
        $('.form-paticipate input[name="concurso-telefono"]').val(''),
        $('.form-paticipate input[name="concurso-localidad"]').val(''),

        $('.form-paticipate input[name="concurso-direccion"]').val(''),
        $('.form-paticipate input[name="concurso-codpost"]').val(''),
        $('.form-paticipate select[name="pais"]').val(''),

        $('.form-paticipate input[name="concurso-provincia"]').val(''),
        $('.form-paticipate input[name="concurso-nombre-foto"]').val(''),
        $('.form-paticipate input[name="concurso-tienda"]').val(''),
        $('.form-paticipate select[name="blanket"]').val(''),


        $('.form-paticipate input[type="checkbox"]').prop('checked', false);
        $('.form-paticipate .rate input[name="rate"]').prop("checked", false),
        $('.form-paticipate .rate input[name="rate"]').prop("checked", false),
        $('.form-paticipate input[name="rate_product"]').val(''),
        $('.form-paticipate textarea[name="rate_comment"]').val(''),

        $('.form-paticipate select[name="pais"]').find('option[value="0"]').attr('selected','selected');
        $('.form-paticipate select[name="pais"]').prop('selectedIndex',0);

        $('.form-paticipate select[name="blanket"]').find('option[value="0"]').attr('selected','selected');
        $('.form-paticipate select[name="blanket"]').prop('selectedIndex',0);

        $('.form-paticipate select[name="pais"]').select2('destroy');
        $('.form-paticipate select[name="pais"]').find("select option").eq(0).html();
        $('.form-paticipate select[name="pais"]').select2();

        $('.form-paticipate select[name="blanket"]').select2('destroy');
        $('.form-paticipate select[name="blanket"]').find("select option").eq(0).html();
        $('.form-paticipate select[name="blanket"]').select2();
    }
}
