import Toastify from 'toastify-js'

export class ToastManager {
    static _instance;

    /**
     * Toast Manager Singleton method
     * @returns instance
     */
    static getInstance (options = null) {
        if(!ToastManager._instance) {
            ToastManager._instance = new ToastManager(options);
        }
        return ToastManager._instance
    }

    _options = {
        gravity: "bottom", // `top` or `bottom`
        position: "right", // `left`, `center` or `right`
        newWindow: true,
        close: true,
        duration: 3000,
        className: 'fama-toast',
        style: {
            background: "#555",
        },
    }

    constructor(options = null) {
        this._options = Object.assign({}, this._options, options)
    }

    /**
     *
     * @param {*} options
     */
    setSettings(options) {
        this._options = Object.assign({}, this._options, options)
    }

    /**
     *
     * @param {*} message
     * @param {*} options
     */
    log(message, options = null) {
        var finalOptions = {}

        finalOptions = Object.assign({}, this._options, options, {text: message})

        Toastify(finalOptions).showToast();
    }

    success(message) {
        this.log(message, {style: {background: '#088325'}})
    }

    error(message) {
        this.log(message, {style: {background: '#a71d1d'}})
    }


}
