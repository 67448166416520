var FileUploadMan = function(args){

	this.input = args.input;
	this.btn = args.btn;
	this.bar = args.bar; // Barra de progreso

	this.hasAutoUpload = args.hasAutoUpload;

	this.uploadVisibilityControl = args.uploadVisibilityControl;
	this.uploadVisibilityClass = 'is-visible';

	this.s_uploadMessage = args.s_uploadMessage;

	this.txts = {
		invalidSizeExtension: '',
		uploading: '',
		uploadingWait: '',
		uploadedOK: '',
		uploadedKO: '',
		uploadAtLeast: '',
		uploadLimitReached: '',
		fileSelected: 'Archivo seleccionado: '
	}

	if (args.txts) {
		if (args.txts.uploading) {
			this.txts.uploading = args.txts.uploading;
		}
		if (args.txts.uploadedOK) {
			this.txts.uploadedOK = args.txts.uploadedOK;
		}
		if (args.txts.uploadedKO) {
			this.txts.uploadedKO = args.txts.uploadedKO;
		}
		if (args.txts.uploadAtLeast) {
			this.txts.uploadAtLeast = args.txts.uploadAtLeast;
		}
		if (args.txts.invalidSizeExtension) {
			this.txts.invalidSizeExtension = args.txts.invalidSizeExtension;
		}
	}

	this.colors = {
		barBg: '',
		barBgKO: ''
	}

	if (args.colors) {
		if (args.colors.barBg) {
			this.colors.barBg = args.colors.barBg;
		}
		if (args.colors.barBgKO) {
			this.colors.barBgKO = args.colors.barBgKO;
		}
	}

	this.onUploadSuccess = typeof(args.onUploadSuccess) === 'function' ? args.onUploadSuccess : false;
	this.onUploadFail = typeof(args.onUploadFail) === 'function' ? args.onUploadFail : false;

	this.ctrl = {
		uploading: false,
		uploads: 0,
		uploadsMin: 1,
		uploadsMax: 5,
		uploadsOK: 0,
		uploadsKO: 0,
		currentFile: '', //Stores filename
	}

	if (args.uploadsMax) {
		this.ctrl.uploadsMax= args.uploadsMax;
	}

	this.style = {
		barOK: {
			backgroundColor: this.colors.barBg
		},
		barKO: {
			backgroundColor: this.colors.barBgKO
		}
	}

	this.maxSize = parseFloat(args.maxSize) > 0 ? parseFloat(args.maxSize) : 0;
	this.extensions = args.extensions;
}

FileUploadMan.prototype._onUploadStart = function(data){
	var self = this;

	if (self.uploads < self.uploadMin) {
		if (self.txts.uploadAtLeast.length > 0) {
			alert(self.txts.uploadAtLeast);
		}
		return;
	}

	var isOk = true;

	if(self.maxSize > 0 && typeof data.files[0] !== 'undefined'){
		size = data.files[0].size;
		type = data.files[0].name.split('.');
		type = type[(type.length - 1)].toLowerCase();
		isOk = self.maxSize > size && ( self.extensions.length > 0 && self.extensions.indexOf(type) >= 0 );
	}

	if (!isOk) {
		--self.ctrl.uploads;
		self.ctrl.uploading = false;
		if (self.txts.invalidSizeExtension){
			alert(self.txts.invalidSizeExtension);
		}
		return;
	}


	++self.ctrl.uploads;
	//self.ctrl.uploading = true;

	var styles = self.style.barOK;
	styles.width = 0;

	$(self.bar).css(styles);

	if (self.txts.uploading.length > 0) {
		$(self.s_uploadMessage).text(self.txts.uploading);
	}

	data.submit();
}

FileUploadMan.prototype._add = function(e,data, callback){
	var self = this;
	if (self.ctrl.uploading === true) {
		if (self.txts.uploadingWait){
			alert(self.txts.uploadingWait);
		}
	} else if (self.ctrl.uploadsOK >= self.ctrl.uploadsMax) {
		if (self.txts.uploadLimitReached){
			alert(self.txts.uploadLimitReached);
		}
		return false;
	} else {
		self.ctrl.currentFile = data.files[0].name;
		$(self.s_uploadMessage).text(self.txts.fileSelected + self.ctrl.currentFile);
		$(self.bar).parent().css({backgroundColor: 'rgba(102,102,102,0.5)'});
		$(self.bar).css({backgroundColor: 'transparent'});
		return true;
	}

}

FileUploadMan.prototype._done = function(e,data){
	var self = this;

	++self.ctrl.uploadsOK;
	self.ctrl.uploading = false;

	if (data.jqXHR && data.jqXHR.responseJSON) {
		var r = data.jqXHR.responseJSON;
		if (r.status) {
			if (r.status === 'ko') {
				alert(r.message);
				if (self.onUploadSuccess !== false) {
					self.onUploadSuccess(e, data, r, self.ctrl);
				}
				return;
			}
		}
	}

	if (self.txts.uploadedOK.length > 0) {
		$(self.s_uploadMessage).text(self.txts.uploadedOK);
	}

	//TODO: remove visibility:
	if (self.onUploadSuccess !== false) {
		self.onUploadSuccess(e, data, r, self.ctrl);
	}
}

FileUploadMan.prototype._fail = function(e,data){
	var self = this;

	++self.ctrl.uploadsKO;
	self.ctrl.uploading = false;

	if (self.colors.barBgKO) {
		$(self.bar).css('background-color', self.colors.barBgKO);
	}

	if (data.jqXHR && data.jqXHR.responseJSON) {
		var r = data.jqXHR.responseJSON;
		if (r.status) {
			if (r.status === 'ko') {
				alert(r.message);
				if (self.txts.uploadedKO.length > 0) {
					$(self.s_uploadMessage).text(self.txts.uploadedKO);
				}
				if (self.onUploadFail !== false) {
					self.onUploadFail(e,data, r, self.ctrl);
				}
				return;
			}
		}
	}

	if (self.txts.uploadedKO.length > 0) {
		$(self.s_uploadMessage).text(self.txts.uploadedKO);
	}
	if (self.onUploadFail !== false) {
		self.onUploadFail(e,data, r, self.ctrl);
	}
}

FileUploadMan.prototype._progress = function(e,data){
	var self = this;
	var progress = parseInt(data.loaded / data.total * 100, 10);
	$(self.bar).css('width', progress + '%');
}

FileUploadMan.prototype.initUpload_auto = function(){
	var self = this;
	var input = $(self.input);

	input.fileupload({
		dataType: 'json',
		add: function (e, data) {

			var status = self._add(e,data);

			if (status === true) {
				self._onUploadStart(data);
			}
		},
		progressall: function (e,data){
			self._progress(e,data);
		},
		done: function (e, data) {
			self._done(e,data);
		},
		fail: function (e, data) {
			self._fail(e,data);
		}
	});
}

FileUploadMan.prototype.initUpload_twoButtons = function(){
	var self = this;
	var input = $(self.input);

	$(self.btn).unbind('click').click(
		function (e) {
			e.preventDefault();
			e.stopPropagation();

			if(input.val() != "") {
			 } else {
				if (self.txts.uploadAtLeast.length > 0) {
					alert(self.txts.uploadAtLeast);
				}
				return;
			 }


		}
	);

	input.fileupload({
		dataType: 'json',
		add: function (e, data) {

			var status = self._add(e,data);
			if (status === true) {
				data.context = $(self.btn).addClass('is-visible').unbind('click').click(
					function (e) {
						e.preventDefault();
						e.stopPropagation();

						if(input.value != "") {
							// you have a file
							self._onUploadStart(data);
						 } else {
							if (self.txts.uploadAtLeast.length > 0) {
								alert(self.txts.uploadAtLeast);
							}
							return;
						 }


					}
				);
			}
		},
		progressall: function (e,data){
			self._progress(e,data);
		},
		done: function (e, data) {
			self._done(e,data);
		},
		fail: function (e, data) {
			self._fail(e,data);
		}
	});
}

FileUploadMan.prototype.initUpload_oneButton = function(){
	var self = this;

	var btn = $(self.btn);
	var input = $(self.input);

	var btnClick = function(e, data){
		e.preventDefault();
		e.stopPropagation();
		if (self.ctrl.uploads == 0) {

			input.click();
		} else {
			if (typeof(data) !== 'undefined') {
				self._onUploadStart(data);
			}
		}
	}

	input.fileupload({
		dataType: 'json',
		add: function (e, data) {

			var status = self._add(e,data);

			if (status === true) {
				data.context = $(self.btn).addClass('is-visible').unbind('click').click(
					function (e) {
						btnClick(e, data);
					}
				);
			}
		},
		progressall: function (e,data){
			self._progress(e,data);
		},
		done: function (e, data) {
			self._done(e,data);
		},
		fail: function (e, data) {
			self._fail(e,data);
		}
	});

	btn.unbind('click').click(
		function (e) {
			btnClick(e);
		}
	);
}
