//use news (fama)
'use strict'
import axios from 'axios'
import { FamaUtils } from '../../classes/FamaUtils'

export class News {
    /** Selectors **/
    $openAccBtn() { return $('.js-open-accordeon') }
    $accordeon() { return $('.js-filter-accordeon') }
    $newsContainer() { return $('.js-news-container .wrap-rest-items .aux-wrap') }
    $currentPage() { return $('.js-page-value') }
    $currentCatid() { return $('.js-catid-value') }
    $currentLocale() { return $('.js-locale-value') }
    $commentsLoader() { return $('.comment-loader') }

    utils = FamaUtils.getInstance()
    currentOffset   = 0;
    MOD_NOTICIAS    = 3;
    fin             = false;

/** Api Endpoints **/
    $loadMoreNews() { return '/api/get-more-news' }

    constructor(){}

    init() {
        this.binds()
    }

    binds() {
        this.$openAccBtn().on( 'click', (e) => this.toggleAccordeon() );
        document.addEventListener('scroll', e => this.onScroll(e))
    }

    toggleAccordeon() {
        let $accordeon = this.$accordeon()[0];
        if ($accordeon.classList.contains('opened')) {
            $accordeon.classList.add('closed');
            $accordeon.classList.remove('opened');
        }
        else {
            $accordeon.classList.add('opened');
            $accordeon.classList.remove('closed');
        }
    }

    onScroll() {
        let contenido = Math.round($('.newsContainer').outerHeight() + Math.round($('.newsContainer').offset().top));

        if (window.scrollY + window.innerHeight >= contenido && !this.fin) {
            this.fin = true;
            //fama.utils.debug('this.fin');

            let data = {
                catid: this.$currentCatid()[0].value,
                page: this.$currentPage()[0].value,
                locale: this.$currentLocale()[0].value,
            }
            if (this.currentOffset <= data.page) {
                this.$commentsLoader().show();
                axios
                    .post(this.$loadMoreNews(), data)
                    .then((response) => {
                        this.currentOffset = data.page;
                        response.data.articles.data.forEach(element => {
                            let news = `<div class="left-item-news newItem rest-items">`;
                            news += `<div class="wrap-img">
                                    <a href="${element.detailUrl}" class="img other-images"`;
                            // element.detailUrl
                            if (element.imagenes) {
                                news += `style="background-image: url('${element.imagenes}')">`
                            }
                            else {
                                news += `style="background-image: url('/images/img/images/foto1.png')">`
                            }
                            news += `</a>
                            </div>`;

                            news += `<div class="wrap-content">
                        <div class="wrap-info d-flex news-content">

                            <div class="info">
                                <div class="text">${element.category ?? ' '}</div>
                                <div class="date-info">${element.fechafixed}</div>
                            </div>

                            <span class="likes-text">
                                <span class="likes-count">${element.likes_count}</span>`;


                            if (!document.cookie.includes(`liked_${element.id}_3`)) {
                                news += `<a href="#" class="like-button" data-item="${element.id}" data-type="3">
                                            <i class="fa fa-heart-o heart heart--pink" aria-hidden="true"></i>
                                        </a>`;
                            }
                            else {
                                news += `<a href="#" class="liked" data-item="${ element.id }" data-type="3">
                                <i class="fa fa-heart heart heart--pink" aria-hidden="true"></i>
                            </a>`
                            }

                            news += `</span >
                                </div >
                                <a class="new-title" href="${element.detailUrl}">
                                    <div class="title">
                                        ${ element.title}
                                    </div>
                                </a>
                                </div >
                            </div > `;
                            this.$newsContainer()[0].innerHTML += news;
                    });
                    this.$currentPage()[0].value++;
                    this.$commentsLoader().hide();
                    this.fin=false;
                })
                .catch((error) => {
                    this.utils.log(error)
                })
            }
        }

    }
}
