import { FadeTranslate } from "../../classes/FadeTranslate"

/**
 *
 */
export class SliderManager{

    /**
     * Singleton pattern
     */
    static _instance
    static getInstance() {
        if(!SliderManager._instance)
        SliderManager._instance = new SliderManager()

        return SliderManager._instance
    }

    static HOME_SLIDER              = 'home'
    static CONTEST_SLIDER           = 'contest'
    static SIMULATION_SLIDER        = 'simulation'
    static VIDEO_SLIDER             = 'video'
    static NONSTOP_SLIDER           = 'nonstop'
    static MBL_COMPANY_SLIDER       = 'company-mobile'
    static COMPANY_SLIDER           = 'company'
    static DESIGN_COMPANY_SLIDER    = 'design-company'
    static INTERNATIONAL_SLIDER     = 'international'
    static INDUSTRY_SLIDER          = 'industry'
    static MADEINSPAIN_SLIDER       = 'madeinspain'
    static FAMALOVERS_SLIDER        = 'famalovers'
    static OURTOURS_SLIDER          = 'ourtours'
    static ENVIRONMENT_SLIDER       = 'environment'

    /**
     *  constructor
     */
    constructor(){
        fama.utils.debug('SlideManager loaded')
    }

    eventListeners() {
        $(window).on( 'resize', () => {
            this.calcHotSpots()
        })
    }

    /**
     *
     */
    initAll() {
        this.sliderHome()
        this.sliderContest()
        this.sliderSimulations()
        this.sliderVideos()
        this.sliderNonStop()
        this.sliderCompanyMobile()
        this.sliderCompany()
        this.sliderDesignCompany()
        this.sliderInternational()
        this.sliderIndustry()
        this.sliderMadeInSpain()
        this.sliderFamaLovers()
        this.sliderOurTours()
        this.sliderEnvironment()
    }

    /**
     *
     * @param {String} slider
     */
    initSlider(slider) {
        switch(slider) {
            case SliderManager.HOME_SLIDER:
                this.sliderHome()
            break;

            case SliderManager.CONTEST_SLIDER:
                this.sliderContest()
            break;

            case SliderManager.SIMULATION_SLIDER:
                this.sliderSimulations()
            break;

            case SliderManager.VIDEO_SLIDER:
                this.sliderVideos()
            break;

            case SliderManager.NONSTOP_SLIDER:
                this.sliderNonStop()
            break;

            case SliderManager.MBL_COMPANY_SLIDER:
                this.sliderCompanyMobile()
            break;

            case SliderManager.COMPANY_SLIDER:
                this.sliderCompany()
            break;

            case SliderManager.DESIGN_COMPANY_SLIDER:
                this.sliderDesignCompany()
            break;

            case SliderManager.INTERNATIONAL_SLIDER:
                this.sliderInternational()
            break;

            case SliderManager.INDUSTRY_SLIDER:
                this.sliderIndustry()
            break;

            case SliderManager.MADEINSPAIN_SLIDER:
                this.sliderMadeInSpain()
            break;

            case SliderManager.FAMALOVERS_SLIDER:
                this.sliderFamaLovers()
            break;

            case SliderManager.OURTOURS_SLIDER:
                this.sliderOurTours()
            break;

            case SliderManager.ENVIRONMENT_SLIDER:
                this.sliderEnvironment()
            break;

        }
    }

    /**
     *
     */
    sliderHome() {
        if($('.slider-home').length == 0 ) return; //Do it better
        let $slider = $('.slider-home')
        let options = {
            centerMode: true,
            slidesToShow: 1,
            infinite: true,
            // initialSlide: 2,
            rows: 0,
            dots: false,
            speed: 3000,
            slidesToScroll: 1,
            swipeToSlide: true,
            focusOnSelect: true,
            //centerPadding: '3.5rem',
            arrows: true,
            autoplay: false,
            //variableWidth: true,
            //pauseOnFocus: true,
            lazyLoad: 'ondemand',
            responsive: [
                {
                    breakpoint: 975,
                    settings: {
                        swipeToSlide: true,
                        arrows: true,
                        centerMode: true,
                        //centerPadding: '4rem',
                        slidesToShow: 1
                    }
                },

            ]
        }

        if($slider.length > 0){
            $slider.slick(options)
            $slider.on('afterChange', function (){
                let slider= $(this).closest('.js-slider-wrap');
                let numActive= slider.find('.slick-slide.slick-current').data('num-index');
                slider.find('.scrollbar-item.isActive').removeClass('isActive');
                slider.find('.scrollbar-home .scrollbar-item[data-num="'+numActive+'"]').addClass('isActive');

            });
        }

        this.calcHotSpots();
    }

    /**
     *
     */
    sliderContest() {
        if($('.slider-home-concurso').length == 0 ) return; //Do it better

        let $element = $('.slider-home-concurso')
        let options = {
            centerMode: true,
            slidesToShow: 1,
            infinite: true,
            initialSlide: 2,
            dots: false,
            rows: 0,
            speed: 500,
            slidesToScroll: 1,
            swipeToSlide: true,
            focusOnSelect: true,
            arrows: true,
            variableWidth: true,
            lazyLoad: 'ondemand',
            responsive: [
                {
                    breakpoint: 975,
                    settings: {
                        swipeToSlide: true,
                        centerMode: true,
                        slidesToShow: 1
                    }
                },

            ]
        }

        $element.slick(options)
        $element.on('afterChange', function (){
            let slider= $(this).closest('.js-slider-wrap');
            let numActive= slider.find('.slick-slide.slick-current').data('num-index');
            slider.find('.scrollbar-item.isActive').removeClass('isActive');
            slider.find('.scrollbar-home .scrollbar-item[data-num="'+numActive+'"]').addClass('isActive');

        });
    }

    /**
     *
     */
    sliderSimulations() {
        if($('.slider-home-simulations-list').length>0){
            let options = {
                centerMode: true,
                slidesToShow: 1,
                infinite: false,
                dots: false,
                speed: 500,
                slidesToScroll: 1,
                focusOnSelect: true,
                arrows: true,

                draggable: false,
                touchMove: false,
                swipeToSlide: false,
                swipe: false,
                //variableWidth: true,
                lazyLoad: 'ondemand',
                responsive: [
                    {
                        breakpoint: 975,
                        settings: {
                            swipeToSlide: true,
                            arrows: true,
                            centerMode: true,
                            //centerPadding: '4rem',
                            slidesToShow: 1,
                            draggable: true,
                            touchMove: true,
                            swipe: true,
                        }
                    },

                ]
            }
            $('.slider-home-simulations-list').slick(options);
        }

        // *** slider of typs of simulations ***
        if($('.slider-type-simulation').length>0){

            // my slick slider options
            const options = {
                centerMode: true,
                slidesToShow: 1,
                infinite: true,
                dots: true,
                speed: 1000,
                slidesToScroll: 1,

                swipeToSlide: false,
                draggable: false,
                touchMove: false,
                swipe: false,

                focusOnSelect: true,
                arrows: false,
                autoplay: true,
                //variableWidth: true,
                lazyLoad: 'ondemand',
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            swipeToSlide: true,
                            arrows: false,
                            centerMode: true,
                            slidesToShow: 1,

                            draggable: true,
                            touchMove: true,
                            swipe: true,
                        }
                    },

                ]
            };

            // let fadeTranslate = new FadeTranslate()
            // fadeTranslate.fadeTextTranslate();
            // utils_2021.fadeTextTranslate.init();

            // my slick slider as const object
            $('.slider-type-simulation').on('init', function() {
                // set this slider as const for use in set time out
                const slider = this;

                // slight delay so init completes render
                setTimeout(function() {

                    // dot buttons
                    let dots = $('.slick-dots > li > button', slider);
                    let num=0;

                    //each dot button function
                    $.each(dots, function(i,e) {
                        // slide id
                        let slide_id = $(this).attr('aria-controls');
                        // custom dot image
                        let dot_img = $('#'+slide_id+' .slider-type-simulation-item').data('dot-img');
                        $(this).attr('data-dot-num', num);
                        num++;
                        $(this).html('<img src="' + dot_img + '" alt="" />');

                    });



                }, 100);


            }).slick(options);

            $('.slider-type-simulation').each(function(){
                $(this).on('afterChange', function (){
                    let slider= $(this);
                    let numActive= slider.find('.slick-dots .slick-active button').data('dot-num');
                    slider.closest('.wrap-slider-simulation-item').find('.scrollbar-item.isActive').removeClass('isActive');
                    slider.closest('.wrap-slider-simulation-item').find('.scrollbar-home .scrollbar-item[data-num="'+numActive+'"]').addClass('isActive');

                })
            })
        }
    }

    /**
     *
     */
    sliderVideos() {
        if($('.slider-videos-home').length > 0){
            let options = {
                centerMode: true,
                slidesToShow: 1,
                //infinite: true,
                initialSlide: 0,
                rows: 0,
                dots: true,
                customPaging : function(slider, i) {
                    var thumb = $(slider.$slides[i]).data();
                    return '<a class="dot" style="height: 100%; width: 100%">'+(i+1)+'</a>';
                },
                speed: 500,
                slidesToScroll: 1,
                swipeToSlide: true,
                focusOnSelect: true,
                //centerPadding: '3.5rem',
                arrows: true,
                lazyLoad: 'ondemand',
                //variableWidth: true,
                //pauseOnFocus: true,
                responsive: [
                    {
                        breakpoint: 975,
                        settings: {
                            swipeToSlide: true,
                            arrows: false,
                            centerMode: true,
                            //centerPadding: '4rem',
                            slidesToShow: 1
                        }
                    },
                ]
            }
            $('.slider-videos-home').slick(options)

            var $slider = $('.slider-videos-home');


            if($('.slider-videos-home .slick-dots').length > 0){

                var widthNumPage=0;
                $slider.find('.slick-dots li').each(function(){
                    widthNumPage=widthNumPage + $(this).width();

                })

                $slider.find('.wrap-progress .progress').css('width', 'calc(100% - ' + widthNumPage +'px)');
            }



            $slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
                $slider.find('.slick-current').find('.wrap-progress .progress').hide();
            });
            $slider.on('afterChange', function(event, slick, currentSlide, nextSlide) {
                $slider.find('.slick-current').find('.wrap-progress .progress').show();

            });

        }
    }

    /**
     *
     */
    sliderNonStop() {
        if($('.home-non-stop .carousel-container').length>0){
            let options = {
                centerMode: false,
                //slidesToShow: 3,
                rows: 0,
                infinite: false,
                initialSlide: 0,
                dots: false,
                speed: 500,
                slidesToScroll: 1,
                swipeToSlide: true,
                focusOnSelect: true,
                variableWidth: true,
                arrows: true,
                lazyLoad: 'ondemand',
                responsive: [
                    {
                        breakpoint: 975,
                        settings: {
                            //slidesToShow: 1
                        }
                    },
                ]
            }
            $('.home-non-stop .carousel-container').slick(options)
        }
    }

    /**
     *
     */
    sliderCompanyMobile() {
        if($('.home-empresa.mvl .empresa-mvl .carousel-container').length>0){
            let options = {
                centerMode: false,
                //slidesToShow: 3,
                rows: 0,
                infinite: false,
                initialSlide: 0,
                dots: false,
                speed: 500,
                //slidesToScroll: 1,
                swipeToSlide: true,
                focusOnSelect: true,
                variableWidth: true,
                arrows: true,
                lazyLoad: 'ondemand',
                responsive: [
                    {
                        breakpoint: 975,
                        settings: {
                            //slidesToShow: 1
                        }
                    },
                ]
            }
            $('.home-empresa.mvl .empresa-mvl .carousel-container').slick(options)
        }
    }

    /**
     *
     */
    sliderCompany() {
        if($('.slider-empresa').length > 0){
            let options = {
                centerMode: true,
                slidesToShow: 1,
                infinite: true,
                initialSlide: 2,
                dots: false,
                rows: 0,
                speed: 500,
                slidesToScroll: 1,
                swipeToSlide: true,
                focusOnSelect: true,
                arrows: true,
                variableWidth: true,
                lazyLoad: 'ondemand',
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            swipeToSlide: true,
                            arrows: false,
                            centerMode: true,
                            slidesToShow: 1,
                            variableWidth: false,
                        }
                    },

                ]
            }
            $('.slider-empresa').slick(options)
        }
    }

    /**
     *
     */
    sliderDesignCompany() {
        if($('.slider-design').length > 0){
            let options = {
                centerMode: true,
                slidesToShow: 1,
                infinite: true,
                rows: 0,
                //initialSlide: 1,
                dots: false,
                speed: 500,
                slidesToScroll: 1,
                swipeToSlide: true,
                focusOnSelect: true,
                arrows: true,
                autoplay: true,
                lazyLoad: 'ondemand',
                //variableWidth: false,
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            swipeToSlide: true,
                            arrows: false,
                            centerMode: true,
                            slidesToShow: 1,
                            variableWidth: false,
                        }
                    },
                ]
            }
            $('.slider-design').slick(options)
            $('.slider-design').on('afterChange', function (){
                let slider= $(this).closest('.js-slider-wrap');
                let numActive= slider.find('.slick-slide.slick-current').data('num-index');
                slider.find('.scrollbar-item.isActive').removeClass('isActive');
                slider.find('.scrollbar-home .scrollbar-item[data-num="'+numActive+'"]').addClass('isActive');
            });
        }
    }

    /**
     *
     */
    sliderInternational() {
        if($('.slider-international').length > 0){
            let options = {
                rows:0,
                centerMode: true,
                slidesToShow: 1,
                infinite: true,
                //initialSlide: 1,
                dots: false,
                speed: 500,
                slidesToScroll: 1,
                swipeToSlide: true,
                focusOnSelect: true,
                arrows: true,
                autoplay: true,
                lazyLoad: 'ondemand',
                //variableWidth: false,
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            swipeToSlide: true,
                            arrows: false,
                            centerMode: true,
                            slidesToShow: 1,
                            variableWidth: false,
                        }
                    },
                ]
            }
            $('.slider-international').slick(options)

            $('.slider-international').on('afterChange', function (){
                let slider= $(this).closest('.js-slider-wrap');
                let numActive= slider.find('.slick-slide.slick-current').data('num-index');
                slider.find('.scrollbar-item.isActive').removeClass('isActive');
                slider.find('.scrollbar-home .scrollbar-item[data-num="'+numActive+'"]').addClass('isActive');
            });
        }
    }

    /**
     *
     */
    sliderIndustry() {
        if($('.slider-industry').length > 0){
            let options = {
                rows: 0,
                centerMode: true,
                slidesToShow: 1,
                infinite: true,
                //initialSlide: 1,
                dots: false,
                speed: 500,
                slidesToScroll: 1,
                swipeToSlide: true,
                focusOnSelect: true,
                arrows: true,
                lazyLoad: 'ondemand',
                //autoplay: true,
                //variableWidth: false,
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            swipeToSlide: true,
                            arrows: false,
                            centerMode: true,
                            slidesToShow: 1,
                            variableWidth: false,
                        }
                    },

                ]
            }
            $('.slider-industry').slick(options)
            $('.slider-industry').on('afterChange', function (){
                let slider= $(this).closest('.js-slider-wrap');
                let numActive= slider.find('.slick-slide.slick-current').data('num-index');
                slider.find('.scrollbar-item.isActive').removeClass('isActive');
                slider.find('.scrollbar-home .scrollbar-item[data-num="'+numActive+'"]').addClass('isActive');

            });
        }
    }

    /**
     *
     */
    sliderMadeInSpain() {
        if($('.slider-madeInSpain').length > 0){
            let num=$('.slider-madeInSpain').data('num');
            if(num < 10){
                num='0'+num;
            }
            let options = {
                rows: 0,
                centerMode: false,
                slidesToShow: 1,
                infinite: false,
                initialSlide: 0,
                dots: true,
                customPaging : function(slider, i) {
                    var thumb = $(slider.$slides[i]).data();
                    let numItem=i+1;
                    if(numItem < 10){
                        numItem='0'+numItem;
                    }
                    return '<a class="dot">'+'<span>'+numItem+' / '+num+'</span>'+'</a>';
                },
                speed: 500,
                slidesToScroll: 1,
                swipeToSlide: true,
                focusOnSelect: true,
                arrows: true,
                variableWidth: true,
            }
            $('.slider-madeInSpain').slick(options)
            var $slider = $('.slider-madeInSpain');
            var $progressBar = $('.progress');
            $slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
                var calc = ( (nextSlide) / (slick.slideCount-1) ) * 100;
                $progressBar
                    .css('background-size', calc + '% 100%')
                    .attr('aria-valuenow', calc );
            });
        }
    }

    /**
     *
     */
    sliderFamaLovers() {
        if($('.slider-famalovers').length > 0){
            let options = {
                rows: 0,
                centerMode: true,
                slidesToShow: 1,
                infinite: false,
                speed: 500,
                slidesToScroll: 1,
                swipeToSlide: true,
                focusOnSelect: true,
                arrows: true,
                autoplay: false,
                lazyLoad: 'ondemand',
                //variableWidth: true,
            }
            $('.slider-famalovers').slick(options)
        }
    }

    /**
     *
     */
    sliderWorkWithUs() {
        if($('.slider-workWithUs').length > 0){
            let options = {
                rows: 0,
                centerMode: true,
                slidesToShow: 1,
                infinite: false,
                speed: 500,
                slidesToScroll: 1,
                swipeToSlide: true,
                focusOnSelect: true,
                arrows: true,
                autoplay: false,
                adaptiveHeight: true,
                lazyLoad: 'ondemand',
            }
            $('.slider-workWithUs').slick(options)
        }
    }

    /**
     *
     */
    sliderOurTours() {
        if($('.slider-ourTours').length > 0){
            var $slider = $('.slider-ourTours');

            $slider.on('init', (event, slick, currentSlide, nextSlide)  =>{
                var maxHeight = -1;
                $('.slider-ourTours.slick-initialized .slick-list').each((i, item) => {
                    Math.ceil($(item).height());
                });
                $('.slider-ourTours .slick-slide').each((i, item) => {
                    if (Math.ceil($(item).height()) >= maxHeight) {
                        maxHeight = Math.ceil($(item).height());
                    }
                });
                $('.slider-ourTours .slick-slide').each((i, item) => {
                    if (Math.ceil($(item).height()) === maxHeight) {
                        $(item).css('padding-top', 7 +'px');
                    }
                });
                $('.slider-ourTours .slick-slide').each((i, item) => {
                    if (Math.ceil($(item).height()) < maxHeight) {
                        $(item).css('margin', Math.ceil((maxHeight-Math.ceil($(item).height()))/2 ) + 'px 0');
                    }
                });
            });

            let options = {
                rows: 0,
                slidesToShow: 1,
                initialSlide: 0,
                dots: false,
                infinite:false,
                speed: 500,
                //draggable: true,
                swipe:true,
                //centerMode: true,
                useTransform:true,
                cssEase: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
                //slidesToScroll: 1,
                //swipeToSlide: true,
                arrows: true,
                autoplay: false,
                adaptiveHeight: true,
                vertical: true,
                verticalSwiping:true,
                prevArrow: '<button class="slide-arrow prev-arrow custom"><div class="prev"></div></button>',
                nextArrow: '<button class="slide-arrow next-arrow custom"><div class="next"></div></button>',
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            swipeToSlide: false,
                            swipe: false,
                            draggable: false,
                        }
                    },

                ]

            }
            $('.slider-ourTours').slick(options);

        }
    }

    /**
     *
     */
    sliderEnvironment(){
        if($('.slider-enviroment').length > 0){
            let options = {
                rows: 0,
                centerMode: true,
                slidesToShow: 1,
                initialSlide: 0,
                dots: true,
                customPaging : function(slider, i) {
                    var thumb = $(slider.$slides[i]).data();
                    return '<a class="dot" style="height: 100%; width: 100%">'+(i+1)+'</a>';
                },
                speed: 500,
                slidesToScroll: 1,
                swipeToSlide: true,
                focusOnSelect: true,
                arrows: false,
                autoplay: false,
                adaptiveHeight: true,
                responsive: [
                    {
                        breakpoint: 975,
                        settings: {
                            arrows: false,
                        }
                    },
                ]
            }
            $('.slider-enviroment').slick(options)

            var $slider = $('.slider-enviroment');
            var $progressBar = $('.wrap-slider-enviroment .progress');
            $slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
                var calc = ( (nextSlide) / (slick.slideCount-1) ) * 100;
                $progressBar
                    .css('background-size', calc + '% 100%')
                    .attr('aria-valuenow', calc );
                $(this).find('.slick-list').height('auto');
            });
        }
    }

    /**
     *
     */
    calcHotSpots() {
        let sliderImgs = $('.slider-home .item-slide .aux-item .slider-image');
        sliderImgs.each(function(){
            let image = $(this)[0];
            let percentageRendered = image.height / image.naturalHeight;
            let totalWidth = percentageRendered * image.naturalWidth;
            let hiddenWidth = totalWidth - image.width;
            let hiddenPerSide = hiddenWidth / 2;

            $(this).siblings().each(function(){
                let correctWidthPositionPixels = (($(this).data('width')/100) * totalWidth) - hiddenPerSide;
                let correctWidthPositionPercent = (correctWidthPositionPixels / image.width)*100;
                $(this).css("left", correctWidthPositionPercent + "%");
            });
        })
    }
}
