//use valoraciones (fama)
'use strict'

export class Ratings
{
    /** Selectors **/
    static $ratingsContainer() { return $('.js-ratingsContainer') }
    static $commentsByStars() { return $('.js-commentsByStars') }
    static $ratings() { return $('.js-ratings') }
    static $reviewsContainer() { return $('.js-reviewResults')}
    static $totalRatings() { return $('.js-total-reviews') }
    static $bar5stars() { return $('.js-5star-bar') }
    static $bar4stars() { return $('.js-4star-bar') }
    static $bar3stars() { return $('.js-3star-bar') }
    static $bar2stars() { return $('.js-2star-bar') }
    static $bar1stars() { return $('.js-1star-bar') }
    static $total5stars() { return $('.js-5star-total') }
    static $total4stars() { return $('.js-4star-total') }
    static $total3stars() { return $('.js-3star-total') }
    static $total2stars() { return $('.js-2star-total') }
    static $total1stars() { return $('.js-1star-total') }
    static $percent5stars() { return $('.js-5star-percent') }
    static $percent4stars() { return $('.js-4star-percent') }
    static $percent3stars() { return $('.js-3star-percent') }
    static $percent2stars() { return $('.js-2star-percent') }
    static $percent1stars() { return $('.js-1star-percent') }
    static $commentsLoader() { return $('.comment-loader') }
    static $dataTotal5() { return $('.total5star') }
    static $dataTotal4() { return $('.total4star') }
    static $dataTotal3() { return $('.total3star') }
    static $dataTotal2() { return $('.total2star') }
    static $dataTotal1() { return $('.total1star') }

    /** Api Endpoints **/
    static $apiSearchRatingsByProduct() { return 'api/search-ratings-by-product' }
    static $apiCommentsByStars() { return 'api/comments-by-stars' }
    static $apiGetRatings() { return '/api/get-ratings' }

    /** Web Endpoints **/
    static webInit() { return '/' }

    static currentOffset = 0;
    static fin=false;

    init()
    {
        Ratings.binds()
    }

    static binds()
    {
        Ratings.$ratingsContainer().change(Ratings.searchRatingsByProduct)
        Ratings.$commentsByStars().click(Ratings.commentsByStars)
        document.addEventListener('scroll', e => Ratings.doOnScroll(e))
    }

    static searchRatingsByProduct()
    {

        let data = {
            product_id: Ratings.$ratingsContainer().val()
        }

        axios
            .post(Ratings.$apiSearchRatingsByProduct(), data)
            .then(function (response) {
                $('.hover-tr').removeClass('isSelectedStars')
                let rContainer = Ratings.$reviewsContainer()[0];
                rContainer.innerHTML = "";
                Ratings.$totalRatings()[0].innerHTML = response.data.totalReviews + ' opiniones' ?? 0 + ' opiniones';
                Ratings.$bar1stars().attr('value', response.data.totalRatingsStar1 ?? 0.00);
                Ratings.$bar2stars().attr('value', response.data.totalRatingsStar2 ?? 0.00);
                Ratings.$bar3stars().attr('value', response.data.totalRatingsStar3 ?? 0.00);
                Ratings.$bar4stars().attr('value', response.data.totalRatingsStar4 ?? 0.00);
                Ratings.$bar5stars().attr('value', response.data.totalRatingsStar5 ?? 0.00);
                Ratings.$total1stars()[0].innerHTML = `${response.data.ratingsStar1 ?? 0} <span class="js-1star-percent">(${response.data.totalRatingsStar1 ?? 0.00}%)</span>`;
                Ratings.$total2stars()[0].innerHTML = `${response.data.ratingsStar2 ?? 0} <span class="js-2star-percent">(${response.data.totalRatingsStar2 ?? 0.00}%)</span>`;
                Ratings.$total3stars()[0].innerHTML = `${response.data.ratingsStar3 ?? 0} <span class="js-3star-percent">(${response.data.totalRatingsStar3 ?? 0.00}%)</span>`;
                Ratings.$total4stars()[0].innerHTML = `${response.data.ratingsStar4 ?? 0} <span class="js-4star-percent">(${response.data.totalRatingsStar4 ?? 0.00}%)</span>`;
                Ratings.$total5stars()[0].innerHTML = `${response.data.ratingsStar5 ?? 0} <span class="js-5star-percent">(${response.data.totalRatingsStar5 ?? 0.00}%)</span>`;
                Ratings.$dataTotal5().data('total5stars', response.data.ratingsStar5 ?? 0);
                Ratings.$dataTotal4().data('total4stars', response.data.ratingsStar4 ?? 0);
                Ratings.$dataTotal3().data('total3stars', response.data.ratingsStar3 ?? 0);
                Ratings.$dataTotal2().data('total2stars', response.data.ratingsStar2 ?? 0);
                Ratings.$dataTotal1().data('total1stars', response.data.ratingsStar1 ?? 0);
                Ratings.$totalRatings().data('totalstars', response.data.totalReviews);

                rContainer.dataset.currentFilter = data.product_id;
                if(response.data.message === null) {
                    response.data.ratings.forEach(element => {
                        // Como no puedo hacer condiciones en una insercción HTML las hago aquí antes y asigno el resultado a la variable
                        let photoField;
                        if (element.image_optimized && element.image_optimized !== null && element.image_optimized !== '') {
                            if (element.product_name) {
                                photoField = `<img src="${element.image_optimized}" alt="${element.product_name}">`;
                            } else {
                                photoField = `<img src="${element.image_optimized}" alt="Imagen del mueble">`
                            }
                        } else {
                            photoField = element.user_name[0].toUpperCase();
                        }

                        let rating = '';
                        if (element.review !== null && element.review !== "") {
                            rating = element.review;
                        }

                        let answer = '';
                        if (element.reply_comment && element.reply_comment !== "") {
                            answer =
                                `<div class="rate-answer">
                                <div>
                                    <img src="css/assets/logofama.svg" alt="">
                                </div>
                                <div class="rate-comment">
                                    ${element.reply_comment}
                                </div>
                            </div>`;
                        }

                        let starsAmount = '';
                        for (let i = 0; i < element.score; i++) {
                            starsAmount += '<div class="star"></div>';
                        }

                        rContainer.innerHTML +=
                        `<div class="item">
                            <div class="rate-header">
                                <div class="photo">
                                    ${photoField}
                                </div>
                                <div>
                                    <div class="name">
                                        ${element.user_name} <span style="font-weight: 300">(${element.locality})</span><span class="rate-order-verified">Compra verificada</span>
                                    </div>
                                    <div class="stars r50" data-star="${element.score}">
                                        ${starsAmount}
                                    </div>
                                    <div class="date">${element.date.substring(0, 10)}</div>
                                </div>
                            </div>
                            <div class="rate-comment">
                                ${rating}
                                ${answer}
                        </div>`
                    });
                    Ratings.doOnScroll('scroll');
                } else {
                    rContainer.innerHTML +=
                        `<div class="item">${response.data.message}</div>`
                }
            })
            .catch(function (error) {
                alert(error)
            })
    }

    static commentsByStars(event)
    {
        let data = {
            product_id: Ratings.$ratingsContainer().val(),
            stars: $(event.target).data('star'),
        }

        axios
            .post(Ratings.$apiCommentsByStars(), data)
            .then(function (response) {
               $('.hover-tr').removeClass('isSelectedStars');
                event.target.parentNode.parentNode.classList.add('isSelectedStars');
                let rContainer = Ratings.$reviewsContainer()[0];
                Ratings.$reviewsContainer().data("offset", response.data.next_rating );
                Ratings.$reviewsContainer().data("currentfilter", data.stars );
                rContainer.innerHTML = "";
                if(response.data.message === null) {
                    response.data.ratingsStar.forEach(element => {
                        // Como no puedo hacer condiciones en una insercción HTML las hago aquí antes y asigno el resultado a la variable
                        let photoField;
                        if (element.image_optimized && element.image_optimized !== null && element.image_optimized !== '') {
                            if (element.product_name) {
                                photoField = `<img src="${element.image_optimized}" alt="${element.product_name}">`;
                            } else {
                                photoField = `<img src="${element.image_optimized}" alt="Imagen del mueble">`
                            }
                        } else {
                            photoField = element.user_name[0].toUpperCase();
                        }

                        let rating = '';
                        if (element.review !== null && element.review !== "") {
                            rating = element.review;
                        }

                        let answer = '';
                        if (element.reply_comment && element.reply_comment !== "") {
                            answer =
                                `<div class="rate-answer">
                                <div>
                                    <img src="css/assets/logofama.svg" alt="">
                                </div>
                                <div class="rate-comment">
                                    ${element.reply_comment}
                                </div>
                            </div>`;
                        }

                        let starsAmount = '';
                        for (let i = 0; i < element.score; i++) {
                            starsAmount += '<div class="star"></div>';
                        }

                        rContainer.innerHTML +=
                            `<div class="item">
                        <div class="rate-header">
                            <div class="photo">
                                ${photoField}
                            </div>
                            <div>
                                <div class="name">
                                    ${element.user_name} <span style="font-weight: 300">(${element.locality})</span><span class="rate-order-verified">Compra verificada</span>
                                </div>
                                <div class="stars r50" data-star="${element.score}">
                                ${starsAmount}
                                </div>
                                <div class="date">${element.date.substring(0, 10)}</div>
                            </div>
                        </div>
                        <div class="rate-comment">
                            ${rating}
                            ${answer}
                    </div>`
                    });
                    Ratings.doOnScroll('scroll');
                } else {
                    rContainer.innerHTML +=
                        `<div class="item">${response.data.message}</div>`
                }
            })
            .catch(function (error) {
                alert(error)
            })
    }

    static doOnScroll(event) {
        //fama.utils.debug('doOnScroll');

        let contenido=Math.round($('.app-list').outerHeight() + Math.round($('.app-list').offset().top));

        // if(window.scrollY + window.innerHeight >= document.documentElement.scrollHeight) {
        if (window.scrollY + window.innerHeight >= contenido && !Ratings.fin) {
            Ratings.fin=true;
            switch(Ratings.$reviewsContainer().data('currentfilter')) {
                case 1:
                    if (Ratings.$reviewsContainer().data('offset') < Ratings.$dataTotal1().data('total1stars')) {
                        Ratings.loadMoreStarComments(1, Ratings.$ratingsContainer().val(), Ratings.$reviewsContainer().data('offset'));
                    }
                break;
                case 2:
                    if (Ratings.$reviewsContainer().data('offset') < Ratings.$dataTotal2().data('total2stars')) {
                        Ratings.loadMoreStarComments(2, Ratings.$ratingsContainer().val(), Ratings.$reviewsContainer().data('offset'));
                    }
                break;
                case 3:
                    if (Ratings.$reviewsContainer().data('offset') < Ratings.$dataTotal3().data('total3stars')) {
                        Ratings.loadMoreStarComments(3, Ratings.$ratingsContainer().val(), Ratings.$reviewsContainer().data('offset'));
                    }
                break;
                case 4:
                    if (Ratings.$reviewsContainer().data('offset') < Ratings.$dataTotal4().data('total4stars')) {
                        Ratings.loadMoreStarComments(4, Ratings.$ratingsContainer().val(), Ratings.$reviewsContainer().data('offset'));
                    }
                break;
                case 5:
                    if (Ratings.$reviewsContainer().data('offset') < Ratings.$dataTotal5().data('total5stars')) {
                        Ratings.loadMoreStarComments(5, Ratings.$ratingsContainer().val(), Ratings.$reviewsContainer().data('offset'));
                    }
                break;
                default:
                    if (Ratings.$reviewsContainer().data('offset') < Ratings.$totalRatings().data('totalstars')) {
                        Ratings.loadMoreProductComments(Ratings.$ratingsContainer().val(), Ratings.$reviewsContainer().data('offset'));
                    }
                break;
            }
        }
    }

    static loadMoreStarComments(stars, product_id, offset) {

        let data = {
            product_id: product_id,
            stars: stars,
            offset: offset,
        }

        if(Ratings.currentOffset <= offset) {
            // Ratings.currentOffset = data.offset;
            Ratings.$commentsLoader().show();
            axios
            .post(Ratings.$apiCommentsByStars(), data)
            .then(function (response) {

                let rContainer = Ratings.$reviewsContainer()[0];
                Ratings.$reviewsContainer().data("offset", response.data.next_rating );
                response.data.ratingsStar.forEach(element => {
                    // Como no puedo hacer condiciones en una insercción HTML las hago aquí antes y asigno el resultado a la variable
                    let photoField;
                    if(element.image_optimized && element.image_optimized !== null && element.image_optimized !== '') {
                        if(element.product_name) {
                            photoField = `<img src="${element.image_optimized}" alt="${element.product_name}">`;
                        }
                        else {
                            photoField = `<img src="${element.image_optimized}" alt="Imagen del mueble">`
                        }
                    }
                    else {
                        photoField = element.user_name[0].toUpperCase();
                    }

                    let rating = '';
                    if(element.review !== null && element.review !== ""){
                        rating = element.review;
                    }

                    let answer = '';
                    if(element.reply_comment && element.reply_comment !== ""){
                        answer =
                            `<div class="rate-answer">
                                <div>
                                    <img src="css/assets/logofama.svg" alt="">
                                </div>
                                <div class="rate-comment">
                                    ${element.reply_comment}
                                </div>
                            </div>`;
                    }

                    let starsAmount = '';
                    for(let i = 0; i < element.score; i++){
                        starsAmount += '<div class="star"></div>';
                    }

                    rContainer.innerHTML +=
                        `<div class="item">
                        <div class="rate-header">
                            <div class="photo">
                                ${photoField}
                            </div>
                            <div>
                                <div class="name">
                                    ${element.user_name} <span style="font-weight: 300">(${element.locality})</span><span class="rate-order-verified">Compra verificada</span>
                                </div>
                                <div class="stars r50" data-star="${element.score}">
                                ${starsAmount}
                                </div>
                                <div class="date">${element.date.substring(0,10)}</div>
                            </div>
                        </div>
                        <div class="rate-comment">
                            ${rating}
                            ${answer}
                    </div>`
                });
                Ratings.$commentsLoader().hide();
                Ratings.fin=false;
            })
            .catch(function (error) {
                alert(error)
            })
        }

    }

    static loadMoreProductComments(product_id, offset) {

        let data = {
            product_id: product_id,
            offset: offset,
        }
        if(Ratings.currentOffset < offset) {
            Ratings.currentOffset = data.offset;
            Ratings.$commentsLoader().show();
            axios
            .post(Ratings.$apiGetRatings(), data)
            .then(function (response) {
                Ratings.$reviewsContainer().data("offset", response.data.next_rating );
                let rContainer = Ratings.$reviewsContainer()[0];

                rContainer.dataset.currentFilter = data.product_id;
                response.data.ratings.forEach(element => {
                    // Como no puedo hacer condiciones en una insercción HTML las hago aquí antes y asigno el resultado a la variable
                    let photoField;
                    if(element.image_optimized && element.image_optimized !== null && element.image_optimized !== '') {
                        if(element.product_name) {
                            photoField = `<img src="${element.image_optimized}" alt="${element.product_name}">`;
                        }
                        else {
                            photoField = `<img src="${element.image_optimized}" alt="Imagen del mueble">`
                        }
                    }
                    else {
                        photoField = element.user_name[0].toUpperCase();
                    }

                    let rating = '';
                    if(element.review !== null && element.review !== ""){
                        rating = element.review;
                    }

                    let answer = '';
                    if(element.reply_comment && element.reply_comment !== ""){
                     answer =
                            `<div class="rate-answer">
                                <div>
                                    <img src="css/assets/logofama.svg" alt="">
                                </div>
                                <div class="rate-comment">
                                    ${element.reply_comment}
                                </div>
                            </div>`;
                    }

                    let starsAmount = '';
                    for(let i = 0; i < element.score; i++){
                        starsAmount += '<div class="star"></div>';
                    }

                    rContainer.innerHTML +=
                    `<div class="item">
                        <div class="rate-header">
                            <div class="photo">
                                ${photoField}
                            </div>
                            <div>
                                <div class="name">
                                    ${element.user_name} <span style="font-weight: 300">(${element.locality})</span><span class="rate-order-verified">Compra verificada</span>
                                </div>
                                <div class="stars r50" data-star="${element.score}">
                                    ${starsAmount}
                                </div>
                                <div class="date">${element.date.substring(0,10)}</div>
                            </div>
                        </div>
                        <div class="rate-comment">
                            ${rating}
                            ${answer}
                    </div>`
                });
                Ratings.doOnScroll('scroll');
                Ratings.$commentsLoader().hide();
                Ratings.fin=false;

                //fama.utils.debug(Ratings.fin);
            })
            .catch(function (error) {
                alert(error)
            })
        }

    }
}
