import {ModuleIndex} from "./modules/ModuleIndex";
import {ModalFinder} from "./modules/ModalFinder";

export class App {
    constructor(options){
        this.rawOptions = options; //I saved it to debug!
        this.defaultOptions = {
            breakpointDesktop: 768,
        };
        this.options = {};
        this.options.breakpointDesktop =  options.breakpointDesktop || this.defaultOptions.breakpointDesktop;
        this.moduleIndex = new ModuleIndex({}, this);
        this.modalFinder = new ModalFinder();
    }

    isMobile(){
        return this.options.breakpointDesktop > $(window).width();
    }

    isDesktop(){
        return !this.isMobile();
    }

    init(){
        console.log('App init');
        this.moduleIndex.init();
        this.modalFinder.init();
    }
}
