/**
 *
 * @name FavouriteHandler
 * @description Handles the favourite button on products
 * @author - LoP
 * @refactor - Ibsen
 * @scope Fama, Famaliving
 * @version 1.0
 *
 */

export class FavouriteHandler {
    constructor() {
        fama.utils.debug("Favourite handler loaded.");
        this.eventListeners()
    }

    /**
     * event listeners
     */
    eventListeners() {
        $('.js-favourite').on('click', (e) => { this.toggleFavourite(e) });
    }

    /**
     * Toggles favourite icon and send an
     * Ajax call to increase fav number
     *
     * NOTE: If favourite is already clicked, this methods will not
     * be called. The html element is set on .favourite class
     * a restrinction "pointer-events: none"
     *
     * @param {jQuery event} e
     */
    toggleFavourite(e) {
        e.stopPropagation();
        e.preventDefault();
        let button = e.currentTarget
        let productID = $(button).attr('data-product-id');
        let data = {
            product_id: productID
        };

        if (!$(button).hasClass('favourite')) {
            $.ajax({
                type: 'POST',
                url: '/api/favorite',
                data: data,
                success(response) {
                    $(button).toggleClass('favourite');
                    let numFavouritesElem = $(button).siblings('.loves-num').length > 0 ? $(button).siblings('.loves-num')[0] : button;
                    let numFavourites = parseInt($(numFavouritesElem).text()) + 1;

                    $(numFavouritesElem).text(numFavourites);
                },
                error(error) {
                }
            });
        }
    }

}
