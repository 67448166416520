/**
 * DraggableSlider
 * //TODO: Encapsulate and reuse
 */
export class DraggableSlider {

    trackWidth = 0;

    constructor() {
        fama.utils.debug('DraggableSlider loaded')
        this.init()

    }

    init(){
        var $selector = $('.box-logos');
        var t = $('.modal-drag-hitzone');

        //var r = $(".dash-line").width() / 24;
        var l = 0;
        var d = 0;

        //set track width
        $(".slider-logos .item-logo").each((i, item) => {
            let $item = $(item)
            var a = $item;
            this.trackWidth += a.width();
        }),
        setTimeout( () => {
            $(".slider-logos").css({
                width: this.trackWidth + "px"
            });
        }, 200)


        //
        setTimeout( () => {
            let w = this.trackWidth //$(".slider-logos").outerWidth();

            Draggable.create(".handler", {
                type:"x",
                //edgeResistance:0.65,
                bounds:".container-drag",
                inertia:true,
                onDragStart: function() {},
                onDragEnd: function() {},
                onDrag: function() {
                    var e, dragSize;
                    dragSize = (e = this).endX * w / e.maxX,
                    TweenMax.to($(".wrap-logos .slider-logos"), 2, {
                        x: -dragSize,
                        ease: Power4.easeOut
                    })
                }
            });
        }, 1500)
    }
}
